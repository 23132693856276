import React from 'react';
import {FloatingPortal} from '@floating-ui/react';
import {Absolute} from '../../layout';

export default function Portal({children}: {children: React.ReactNode}) {
    return (
        <FloatingPortal>
            <Absolute top={0} left={0} zIndex="Portal">
                {children}
            </Absolute>
        </FloatingPortal>
    );
}
