import {createContext, useContext} from 'react';
import Analytics from './Analytics';

export const AnalyticsContext = createContext<Analytics | undefined>(undefined);

export const AnalyticsProvider = AnalyticsContext.Provider;

export function useAnalyticsUnsafe(): Analytics | undefined {
    return useContext(AnalyticsContext);
}

export function useAnalytics(): Analytics {
    const analytics = useAnalyticsUnsafe();
    if (!analytics) throw new Error('AnalyticsContext is not yet initialized');
    return analytics;
}
