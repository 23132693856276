const safeParse = (x: string | null) => {
    try {
        return x == null ? x : JSON.parse(x);
    } catch (e) {
        return x;
    }
};

class LocalStorage {
    _isAvailable: boolean;
    _store: Record<string, any>;
    constructor() {
        this._store = {};
        try {
            const storage = window.localStorage;
            const x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            this._isAvailable = true;
        } catch (e) {
            this._isAvailable = false;
        }
    }

    getItem<T>(key: string): T | null {
        if (this._isAvailable) return safeParse(window.localStorage.getItem(key));
        return this._store[key] ?? null;
    }

    setItem(key: string, value: any) {
        if (this._isAvailable) {
            window.localStorage.setItem(
                key,
                typeof value === 'string' ? value : JSON.stringify(value)
            );
        } else {
            this._store[key] = value;
        }
    }

    removeItem(key: string) {
        if (this._isAvailable) {
            window.localStorage.removeItem(key);
        } else {
            delete this._store[key];
        }
    }

    /**
    Subscribe to localStorage changes from other pages/tabs

    @note This won't work on the same page that is making the changes — it is really a way
    for other pages to sync any changes that are made.
    @link https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event
    */
    listen(cb: () => void) {
        if (typeof window !== 'undefined') {
            window.addEventListener('storage', cb);

            return () => {
                window.removeEventListener('storage', cb);
            };
        }

        return () => {};
    }
}

export default new LocalStorage();
