import useDebounceHover from './useDebounceHover';

export type PreloadConfig = {
    request: any;
    responseKey: string;
    payload: any;
};

export default function usePreload(config?: PreloadConfig) {
    const {request, responseKey, payload} = config || {};
    const message = request?.useRequest({responseKey});

    const mouseOutAction = () => null;
    const mouseOverAction = () => {
        if (message?.isEmpty) {
            message.request(payload);
        }
    };

    const [onHover] = useDebounceHover({mouseOutAction, mouseOverAction});
    return onHover;
}
