import React from 'react';
import DeadEnd from '../affordance/DeadEnd';
import Text from '../affordance/Text';
import Link from '../affordance/Link';
import {useAnalytics} from '../index';
import InBox from '../scenes/stampy_in_box';

export default function DeadEndNoTeams() {
    const analytics = useAnalytics();
    return (
        <DeadEnd
            title="Uh Oh!"
            stampy={() => <InBox />}
            action={() => <Link href="/logout">Return to sign in</Link>}
        >
            <Text>
                Looks like you don&apos;t have access to any active Bigdatr teams. If you think this
                might be an error{' '}
                <Link as="span" onClick={() => analytics.chat()}>
                    please get in touch.
                </Link>
            </Text>
        </DeadEnd>
    );
}
