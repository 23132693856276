export default function depsAreEqual(
    prevDeps: React.DependencyList,
    deps: React.DependencyList
): boolean {
    if (prevDeps.length !== deps.length) return false;
    for (let i = 0; i < prevDeps.length; i++) {
        if (Object.is(prevDeps[i], deps[i])) {
            continue;
        }
        return false;
    }
    return true;
}
