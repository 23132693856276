import {mediaTypeLabels} from 'bigdatr-theme';

export type MediaTypeName =
    | 'Out of Home'
    | 'Television'
    | 'STV'
    | 'Regional TV'
    | 'Magazine'
    | 'Classified'
    | 'Newspaper'
    | 'Digital'
    | 'Digital Display'
    | 'Digital Video'
    | 'Digital Social'
    | 'Digital Search'
    | 'Regional Radio'
    | 'Radio';

export type MediaTypeInput = {id?: string; name: string; canonicalName: string};

export default class MediaType {
    private data: MediaTypeInput;
    type = 'mediaType' as const;

    static digitalMediaTypes = [
        'Digital',
        'Digital Search',
        'Digital Display',
        'Digital Video',
        'Digital Social'
    ];

    constructor(input: MediaTypeInput) {
        this.data = input;
    }

    static isClassified(canonicalName: string) {
        return canonicalName === 'Classified';
    }

    static isDigital(canonicalName: string) {
        return MediaType.digitalMediaTypes.includes(canonicalName);
    }

    static isTraditional(canonicalName: string) {
        return !MediaType.digitalMediaTypes.includes(canonicalName);
    }

    static isRadio(canonicalName: string) {
        return ['Regional Radio', 'Radio'].includes(canonicalName);
    }

    static isOoh(input: string) {
        return input === 'Out of Home' || input === 'ac2c56b9-eb09-4d43-806b-0dd7146d904d';
    }

    static isTv(canonicalName: string) {
        return ['Regional TV', 'Television'].includes(canonicalName);
    }

    static isStv(canonicalName: string) {
        return canonicalName === 'STV';
    }

    get id() {
        return this.data.id || this.data.name;
    }

    get canonicalName() {
        return this.data.canonicalName;
    }

    /** custom names - temporary until we enable renaming. when we enable it use this.data.name */
    get label(): string {
        return mediaTypeLabels[this.canonicalName] || this.canonicalName;
    }

    get color() {
        return undefined;
    }
}
