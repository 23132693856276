import React, {useState} from 'react';
import InOutForm from '~/affordance/InOutForm';
import {Input} from 'bigdatr-style';
import {Link} from 'bigdatr-style';
import {DropdownFilter} from 'bigdatr-style';
import {MappingFormProps} from '../MappingUpdateStructure';
import Mapping from '../data/Mapping';
import pick from 'unmutable/pick';
import Term from '~/affordance/Term';
import {SchemaItem} from 'bigdatr-style/src/affordance/Table';

export const update = (data: Mapping) => ({
    payload: pick(['id', 'bigdatrBrand', 'bigdatrIndustry', 'note', 'status'])(data),
    query: `
        mutation MappingUpdate($payload: SmiBrandMappingMutation!) {
            mapping {
                mappingUpdate: smiBrandMappingUpdate(mutation: $payload) {
                    id
                    type: __typename
                    createdAt
                    status
                    note
                    smiClientName
                    smiProductName
                    smiProductCategories
                    smiProductSubcategories
                    bigdatrIndustries
                    bigdatrBrand
                }
            }
        }
    `
});

export const choiceType = 'smiBrandChoices';
export const choiceFragment = () => `
    ... on SmiBrandChoice {
        bigdatrBrand
    }
`;

export const queryFragment = () => `
    ... on SmiBrandMapping {
        smiClientName
        smiProductName
        smiProductCategories
        smiProductSubcategories
        bigdatrIndustries
        bigdatrBrand
    }
`;

export const tableSchema = (): Array<SchemaItem<Mapping>> => {
    return [
        {
            key: 'smiClientName',
            heading: 'Client Name',
            value: (mapping) => mapping.smiClientName,
            render: (mapping) => <Term>{mapping.smiClientName}</Term>
        },
        {
            key: 'smiProductName',
            heading: 'Product Name',
            value: (mapping) => mapping.smiProductName,
            render: (mapping) => <Term>{mapping.smiProductName}</Term>
        },
        {
            key: 'bigdatrBrand',
            heading: 'BigDatr Brand',
            value: (mapping) => mapping.bigdatrBrand || 'Empty'
        }
    ];
};

export const Form: React.FC<MappingFormProps<Mapping>> = (props) => {
    const {mapping, onChange} = props;
    const {choices} = props;
    const id = (item: Mapping) => item.bigdatrBrand;
    const [freeText, setFreeText] = useState(false);
    const {
        smiProductName,
        smiClientName,
        smiProductCategories,
        smiProductSubcategories,
        bigdatrIndustries
    } = mapping;

    return (
        <InOutForm
            inTable={{
                'Client Name': smiClientName,
                'Product Name': smiProductName,
                'Product Category': smiProductCategories ? smiProductCategories.join(', ') : '-',
                'Product Subcategory': smiProductSubcategories
                    ? smiProductSubcategories.join(', ')
                    : '-',
                'BigDatr Industry': bigdatrIndustries ? bigdatrIndustries.join(', ') : '-'
            }}
            outButton={
                <Link
                    onClick={() => {
                        setFreeText(!freeText);
                        onChange({bigdatrBrand: ''});
                    }}
                    children={freeText ? 'existing brands' : 'free text'}
                />
            }
            children={
                freeText ? (
                    <Input
                        value={mapping.bigdatrBrand}
                        onChange={(bigdatrBrand) => onChange({bigdatrBrand})}
                    />
                ) : (
                    <DropdownFilter
                        control="Select Brand"
                        successAction="Select"
                        value={mapping}
                        onChange={(next) => onChange({...next})}
                        multi={false}
                        id={id}
                        label={id}
                        options={choices}
                    />
                )
            }
        />
    );
};
