import React from 'react';
import {MappingFormProps} from '../MappingUpdateStructure';
import pick from 'unmutable/pick';
import Mapping from '~/mapping/data/Mapping';
import InOutForm from '~/affordance/InOutForm';
import {DropdownFilter} from 'bigdatr-style';
import Term from '~/affordance/Term';
import {SchemaItem} from 'bigdatr-style/src/affordance/Table';

export const update = (data: Mapping) => ({
    payload: pick(['id', 'bigdatrPublicationId', 'note', 'status'])(data),
    query: `
        mutation MappingUpdate($payload: AdmonChannelMappingMutation!) {
            mapping {
                mappingUpdate: admonChannelMappingUpdate(mutation: $payload) {
                    id
                    type: __typename
                    createdAt
                    status
                    note
                    admonChannelName
                    admonTypeName
                    bigdatrPublicationId
                }
            }
        }
    `
});

type AdmonChannelChoice = {
    bigdatrPublicationId: string;
    bigdatrPublicationName: string;
    bigdatrPublicationType: 'Television' | 'STV' | 'Radio' | 'Regional Radio' | 'Regional TV';
};
export const choiceType = 'admonChannelChoices';
export const choiceFragment = () => `
    ... on AdmonChannelPublicationChoice {
        bigdatrPublicationId
        bigdatrPublicationName
        bigdatrPublicationType
    }
`;

export const queryFragment = () => `
    ... on AdmonChannelMapping {
        admonChannelName
        admonTypeName
        bigdatrPublicationId
        bigdatrPublicationName
    }
`;

export const tableSchema = (): Array<SchemaItem<Mapping>> => {
    return [
        {
            heading: 'Admon Channel',
            key: 'admonChannelName',
            value: (mapping) => mapping.admonChannelName,
            render: (mapping) => <Term>{mapping.admonChannelName}</Term>
        },
        {
            heading: 'Admon Type',
            key: 'admonTypeName',
            value: (mapping) => mapping.admonTypeName,
            render: (mapping) => <Term>{mapping.admonTypeName}</Term>
        },
        {
            heading: 'BigDatr Publication',
            key: 'bigdatrPublicationName',
            value: (mapping) => mapping.bigdatrPublicationName,
            render: (mapping) => <Term>{mapping.bigdatrPublicationName}</Term>
        }
    ];
};

export const Form: React.FC<MappingFormProps<AdmonChannelChoice>> = (props) => {
    const {mapping, onChange} = props;
    const {choices} = props;
    const id = (item: Mapping) => item.bigdatrPublicationId;
    const label = (item: Mapping) => item.bigdatrPublicationName;
    const {admonChannelName, admonTypeName} = mapping;

    const filteredChoices = choices.filter((cc) => {
        if (admonTypeName === 'Television')
            return (
                cc.bigdatrPublicationType === 'Television' ||
                cc.bigdatrPublicationType === 'STV' ||
                cc.bigdatrPublicationType === 'Regional TV'
            );

        if (admonTypeName === 'Radio')
            return (
                cc.bigdatrPublicationType === 'Radio' ||
                cc.bigdatrPublicationType === 'Regional Radio'
            );
    });

    return (
        <InOutForm
            inTable={{
                'Admon Channel': admonChannelName,
                'Admon Type': admonTypeName
            }}
        >
            <DropdownFilter
                selectingValueCallsOnChange
                control="Select Publication"
                successAction="Select"
                value={mapping}
                onChange={(next) => {
                    if (next) {
                        const {bigdatrPublicationId, bigdatrPublicationName} = next;
                        onChange({bigdatrPublicationId, bigdatrPublicationName});
                    }
                }}
                multi={false}
                id={id}
                label={label}
                options={filteredChoices.map(({bigdatrPublicationId, bigdatrPublicationName}) => ({
                    bigdatrPublicationId,
                    bigdatrPublicationName
                }))}
            />
        </InOutForm>
    );
};
