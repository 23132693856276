import React from 'react';
import {Wrapper, Box, Flex, FlexCenter} from '../../layout';
import Text from './Text';
import Paper from './Paper';
import Boom from '../error/Boom';

type Props = {
    code?: string;
    title?: React.ReactNode;
    stampy?: () => React.ReactNode;
    action?: null | (() => React.ReactNode);
    children?: React.ReactNode;
    boom?: Boom;
    small?: boolean;
    cutout?: boolean;
};

export default function DeadEnd(props: Props) {
    const {code, stampy, action, small, cutout} = props;

    let title = props.title;
    let children = props.children;
    const stampyNode = stampy?.();

    if (props.boom) {
        title = props.boom.name;
        children = props.boom.message;
    }

    if (small) {
        return (
            <Paper cutout={cutout} py={5} px={3} textAlign="center">
                <Text as="p" textStyle="heading3" mb={1}>
                    {title}
                </Text>
                <Text>{children}</Text>
                {action && <FlexCenter pt={3}>{action()}</FlexCenter>}
            </Paper>
        );
    }

    return (
        <Paper display="flex" cutout={cutout} textAlign="center" px={4} py={5} height="100%">
            <Wrapper>
                <Flex flexDirection="column" alignItems="center" maxWidth="50rem">
                    {title && (
                        <Box mb={2}>
                            <Text as="h1" textStyle="heading0">
                                {title}
                            </Text>
                        </Box>
                    )}
                    {children && <Text textStyle="heading4">{children}</Text>}
                    {stampyNode && <Box mt={4} mb={3} children={stampyNode} />}
                    {action && <Box py="3">{action()}</Box>}
                    {code && <Text color="muted">{code}</Text>}
                </Flex>
            </Wrapper>
        </Paper>
    );
}
