import React from 'react';
import {Wrapper, FlexColumn, Flex, Grid} from 'bigdatr-style/layout';
import {DeadEnd} from 'bigdatr-style';
import {Paper} from 'bigdatr-style';
import {Text} from 'bigdatr-style';
import {ButtonRefresh} from 'bigdatr-style';
import {IconWarning} from 'bigdatr-style/icon';
import {Loader} from 'bigdatr-style';
import {Button} from 'bigdatr-style';
import {useAlertData} from '~/feature/Alerts/useAlertData';

export default function AlertsPage() {
    const {isFetching, isRefetching, alerts, request} = useAlertData();
    return (
        <Wrapper py={4}>
            <FlexColumn gap={3}>
                <Flex gap={3}>
                    <Text textStyle="heading2">Alerts</Text>
                    <ButtonRefresh loading={isRefetching} onRequest={() => request()} />
                </Flex>
                {isFetching || isRefetching ? (
                    <Loader />
                ) : alerts.length ? (
                    alerts.map(({message, to}, index) => (
                        <Paper maxWidth="32rem" round outlined p={3} key={index}>
                            <Grid
                                alignItems="center"
                                gridTemplateColumns="2rem auto 7rem"
                                gridGap={3}
                            >
                                <IconWarning size="2rem" color="red" />
                                {message}
                                <Button secondary to={to}>
                                    Investigate
                                </Button>
                            </Grid>
                        </Paper>
                    ))
                ) : (
                    <DeadEnd cutout small title="Nothing to worry about">
                        There are currently no alerts.
                    </DeadEnd>
                )}
            </FlexColumn>
        </Wrapper>
    );
}
