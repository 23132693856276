import React from 'react';
import DeadEnd from '../affordance/DeadEnd';
import Stampy from '../scenes/stampy_in_box';

export default function DeadEndFeedNotFound() {
    return (
        <DeadEnd title="Sorry, we couldn't find this report" stampy={Stampy}>
            {
                "We searched everywhere but we couldn't find this report. It may have been previously deleted or you may need to ask the owner to share the report."
            }
        </DeadEnd>
    );
}
