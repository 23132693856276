import {LocalStorageStore, ThemeType, ThemeConfig} from 'bigdatr-style';
import {adContentKeys} from '~/filters/filterKeys';

type Settings = {
    theme: ThemeType['name'];
    fontStack: ThemeConfig['fontStack'];
    spacing: ThemeConfig['spacing'];
    autoplay: boolean;
    ruleDuplicationPrefix: string;
    ruleDuplicationSuffix: string;
    defaultChangesetSelectionColumns: string[];
    visibleCreativeAttributes: string[];
};

const {store, useStore} = LocalStorageStore<Settings>('bd-settings', {
    theme: 'Light',
    fontStack: 'bigdatr',
    spacing: 'normal',
    autoplay: false,
    ruleDuplicationPrefix: '',
    ruleDuplicationSuffix: ' Copy',
    defaultChangesetSelectionColumns: ['brand', 'industry', 'mediaType'],
    visibleCreativeAttributes: adContentKeys
});

export const useSettings = useStore;
export default store;
