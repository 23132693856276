export const shuffleArray = <T>(source: Array<T>) => {
    const array = source.slice(0);
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};

export function chunk<T>(chunkSize: number, array: T[]): T[][] {
    return Array(Math.ceil(array.length / chunkSize))
        .fill(null)
        .map((_, index) => index * chunkSize)
        .map((begin) => array.slice(begin, begin + chunkSize));
}

//
// Sort by a value rather than manually comparing a to b.
// Used as a replacement for unmutable/soryBy which is basically the same as:
// https://immutable-js.com/docs/v4.0.0-rc.14/List/#sortBy()
// sortBy(list, (data) => data.name)
//
export function sortBy<T>(
    array: T[],
    predicate: (item: T) => string | number | boolean | Date,
    descending = false
): T[] {
    return [...array].sort((aa, bb) => {
        const aValue = descending ? predicate(bb) : predicate(aa);
        const bValue = descending ? predicate(aa) : predicate(bb);
        if (typeof aValue === 'number' && typeof bValue === 'number') return aValue - bValue;
        return String(aValue).localeCompare(String(bValue));
    });
}

export function groupBy<T>(array: T[], predicate: (item: T) => string): Record<string, T[]> {
    return array.reduce((rr, ii) => {
        const key = predicate(ii);
        rr[key] ||= [];
        rr[key].push(ii);
        return rr;
    }, {});
}

// @todo unmutable/equals
