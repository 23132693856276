import React from 'react';
import {Svg} from '../../layout';
import {LightTheme as c} from 'bigdatr-theme';

const stampyInBox = () => (
    <Svg height={{_: '8rem', sm: '13rem'}} viewBox="0 0 320 219">
        <path d="M187 219 46 165l136-57.5 138 57L187 219Z" fill="#000" fillOpacity=".267" />
        <path d="m160 122.5-113.1 43V79L160 36v86.5Z" fill="#99896D" />
        <path d="m271 165-113-43V36l113 43v86Z" fill="#AA9C88" />
        <path d="M45.9 80 .1 55l113.1-42L159 37 45.9 80Z" fill="#C2B59B" />
        <path d="M271.4 80 158.2 37l38.7-37L310 42l-38.6 38Z" fill="#C2B59B" />
        <path
            d="M247 95.2c0-13.2-9.6-24.1-22.2-26-.4-14.6-12.3-26.2-27-26.2-14.6 0-26.4 11.1-26.9 26.1H97.5c-9.6 0-17.5 7.7-17.5 17.3V174h28v-34h14v34h32v-62h14v62h37v-43h14v17.7l28 8.1V95.2ZM228.8 92c-.3 1.5-1.5 2.7-3.1 3.1-3 .7-5.6-1.9-4.9-4.9.3-1.5 1.5-2.7 3.1-3.1 2.9-.6 5.6 2 4.9 4.9Z"
            fill="#7338B6"
        />
        <path d="m219 148 14.1-21.1 22.9 15.4-9.6 14.3L219 148Z" fill={c.brand} />
        <path d="m159 122 113.1-43v85.5l-113.1 43V122Z" fill="#99896D" />
        <path d="M193.6 175.5 159 122l113.1-42 34.7 52.5-113.2 43Z" fill="#C2B59B" />
        <path d="m46 79 113 43v86L46 165V79Z" fill="#AA9C88" />
        <path d="M122.4 173 9.2 130l36.7-51.5 113.1 42-36.6 52.5Z" fill="#C2B59B" />
    </Svg>
);

export default stampyInBox;
