export default class Mapping {
    id: string;
    type: string;
    createdAt: string;
    updatedAt: string;
    status: 'UNMAPPED' | 'PARTIAL' | 'COMPLETE' | 'IGNORED';
    note: string;
    priority: 'HIGH' | 'MEDIUM' | 'LOW' | 'NOT_PRIORITIZED';

    // Similarweb
    brand: string;
    industry: string;
    url: string[];

    // SMI Industry
    smiProductCategory: string;
    smiProductSubcategory: string;
    bigdatrIndustries: string[];
    bigdatrIndustry: string;

    // SMI MediaType
    smiMediaType: string;
    smiMediaSubType: string;
    bigdatrMediaType: string;

    // SMI Brand
    smiProductName: string;
    smiClientName: string;
    bigdatrBrand: string;
    smiProductCategories: string[];
    smiProductSubcategories: string[];

    // Publication
    gfkStationName: string;
    oztamChannelName: string;
    oztamRegionName: string;
    admonChannelName: string;
    admonTypeName: string;
    bigdatrPublicationId: string;
    bigdatrPublicationName: string;

    // Ad Clarity
    adClarityAdvertiserId: string;
    adClarityAdvertiserDomain: string;

    constructor(data: Partial<Mapping>) {
        for (const key in data) {
            this[key] = data[key];
        }
    }

    static statusOptions = [
        {value: undefined, label: 'All'},
        {value: 'UNMAPPED' as const, label: 'Unmapped'},
        {value: 'PARTIAL' as const, label: 'Partial'},
        {value: 'IGNORED' as const, label: 'Ignored'},
        {value: 'COMPLETE' as const, label: 'Done'}
    ];

    static priorityOptions = [
        {value: undefined, label: 'All'},
        {value: 'HIGH' as const, label: 'High'},
        {value: 'MODERATE' as const, label: 'Med'},
        {value: 'LOW' as const, label: 'Low'}
    ];

    get statusColor() {
        const statusColors = {
            UNMAPPED: 'red',
            COMPLETE: 'green',
            PARTIAL: 'orange',
            IGNORED: 'muted'
        };

        if (this.status === 'UNMAPPED') return this.priorityColor;
        return statusColors[this.status] ?? 'foreground';
    }

    get priorityColor() {
        return (
            {
                HIGH: 'red',
                MODERATE: 'orange',
                LOW: 'yellow'
            }[this.priority] || 'red'
        );
    }

    get priorityLabel(): string {
        return (
            {
                HIGH: 'High',
                MODERATE: 'Med',
                LOW: 'Low'
            }[this.priority] || 'High'
        );
    }
}
