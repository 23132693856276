import styled from 'styled-components';
import {Box} from '../../layout';

export default styled(Box).attrs((props: {height: number | string}) => ({
    ...props,
    'data-testid': 'Skeleton',
    height: props.height || '1rem'
}))`
    background-color: ${(props) => props.theme.colors.background1};
    border-radius: 0.2rem;
    animation-name: SkeletonBreath;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    @keyframes SkeletonBreath {
        0% {
            opacity: 0.3;
        }
        50% {
            opacity: 0.7;
        }
        100% {
            opacity: 0.3;
        }
    }
`;
