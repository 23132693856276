export type RegionInput = {id?: string; name: string};

export default class Region {
    private data: RegionInput;
    type = 'region' as const;

    constructor(input: RegionInput) {
        this.data = input;
    }

    get id() {
        return this.data.id || this.data.name;
    }

    get label() {
        return this.data.name;
    }
}
