import React, {lazy, LazyExoticComponent, ComponentType} from 'react';
import ErrorHandler from './ErrorHandler';

export default function safeLazyImport<P>(
    importPromise: () => Promise<{default: ComponentType<P>}>
): LazyExoticComponent<ComponentType<P>> {
    return lazy(async () => {
        try {
            return await importPromise();
        } catch (e) {
            return {default: () => <ErrorHandler errorObject={e} />};
        }
    });
}
