import Button from './Button';
import Tooltip from './Tooltip';
import {IconRefresh} from '../../icon';
import React from 'react';

export default function ButtonRefresh(props: {
    onRequest: () => void;
    loading?: boolean;
    tertiary?: boolean;
}) {
    return (
        <Tooltip content="Refresh">
            <Button
                thin
                secondary={!props.tertiary}
                tertiary={props.tertiary}
                loading={props.loading}
                onClick={props.onRequest}
                icon={<IconRefresh />}
            />
        </Tooltip>
    );
}
