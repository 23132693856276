/** Sometimes with enty we have to make an inital request to kick off an async job, and then we poll
 * for the result of the job to see if its finished */
export default async function* createPollingGenerator<CreateResponse, PollResponse>(config: {
    createRequest: () => Promise<CreateResponse>;
    pollRequest: (createResponse: CreateResponse) => Promise<PollResponse>;
    pollWhile: (pollResponse: PollResponse) => boolean;
    interval?: number;
}) {
    const {createRequest, pollRequest, pollWhile, interval = 1000} = config;
    let isPolling = true;
    const createResponse = await createRequest();
    while (isPolling) {
        const response = await pollRequest(createResponse);
        yield response;
        await new Promise((resolve) => setTimeout(resolve, interval));
        if (!pollWhile(response)) isPolling = false;
    }
}
