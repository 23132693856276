import {createContext, useContext} from 'react';
import {Expression, Group} from '~/filters/data/parseFilters';
import React from 'react';
import {useLocalStorage} from 'bigdatr-style';

// @intent - we have copy/paste button for filters. firefox is trash and didn't implement the
// navigator.clipboard.readText() method, so when we copy filters, we are just setting this context
// to the passed in value. but also it might be good to keep using this even if firefox implements
// the readText method, because having a JSON in your clipboard isn't very useful outside of the app
export const FilterClipboardContext = createContext<FilterClipboardContextData | undefined>(
    undefined
);

export type ClipboardFilters =
    | {
          type: 'rootCopy';
          filters: Expression;
      }
    | {
          type: 'expressionCopy';
          filters: Expression[];
      }
    | {
          type: 'groupCopy';
          filters: Group;
      }
    | null;

type FilterClipboardContextData = {
    filterClipboard: ClipboardFilters;
    setFilterClipboard: (next: ClipboardFilters) => void;
};

export function useFilterClipboard() {
    const context = useContext(FilterClipboardContext);
    if (!context) throw new Error('FilterClipboardContext is not yet initialized');
    return context;
}

export function FilterClipboardProvider(props: {children: React.ReactNode}) {
    const [filterClipboard, setFilterClipboard] = useLocalStorage<ClipboardFilters>(
        'bd-dataEntry-copiedFilters'
    );

    return (
        <FilterClipboardContext.Provider value={{filterClipboard, setFilterClipboard}}>
            {props.children}
        </FilterClipboardContext.Provider>
    );
}
