import React, {ReactNode} from 'react';
import {Table, TableBody, TableRow, TableHeadCell, TableCell} from '../../layout';
import Text from './Text';

type ObjectProps = {
    width?: string;
    data: Record<string, ReactNode>;
    zebra?: boolean;
    tight?: boolean;
    bordered?: boolean;
    headerWidth?: string;
    cellAlign?: string;
    onlyBody?: boolean;
    whiteSpace?: React.CSSProperties['whiteSpace'];
};
type MapProps = {
    width?: string;
    map: Map<ReactNode, ReactNode>;
    zebra?: boolean;
    tight?: boolean;
    headerWidth?: string;
    cellAlign?: string;
    onlyBody?: boolean;
    whiteSpace?: React.CSSProperties['whiteSpace'];
};

export default function TableKeyValue(props: ObjectProps | MapProps) {
    const {zebra, tight, headerWidth, whiteSpace = 'nowrap', ...otherProps} = props;
    const cellPadding = tight ? 0 : 1;

    let rows: Array<[ReactNode, ReactNode]> = [];
    if ('data' in props) rows = Object.entries(props.data);
    if ('map' in props) rows = [...props.map];

    const elements = rows
        .filter(([, value]) => value)
        .map(([key, value], index) => (
            <TableRow
                key={index}
                backgroundColor={zebra && index % 2 !== 0 ? 'background1' : undefined}
            >
                <TableHeadCell
                    p={cellPadding}
                    pl={zebra ? cellPadding : 0}
                    width={headerWidth}
                    verticalAlign={props.cellAlign}
                >
                    <Text fontWeight="bold" whiteSpace={whiteSpace}>
                        {key}
                    </Text>
                </TableHeadCell>
                <TableCell p={cellPadding} pl={3} verticalAlign={props.cellAlign}>
                    {value}
                </TableCell>
            </TableRow>
        ));

    const body = <TableBody>{elements}</TableBody>;
    return props.onlyBody ? body : <Table {...otherProps}>{body}</Table>;
}
