import {MouseEventHandler} from 'react';
type Config = {
    debounce?: number;
    mouseOverAction: () => void;
    mouseOutAction: () => void;
};

let currentTarget: EventTarget | null;

/**
Create a mouseover/out callback that will wait a small amount before triggering
Also returns a cancel function if the component is likely to be unmounted before the dounce finishes
*/
export default function useDebounceHover(config: Config): [MouseEventHandler, () => void] {
    let hasCancelled = false;
    const {debounce, mouseOverAction, mouseOutAction} = config;

    return [
        (e) => {
            if (e.type === 'mouseout' && !hasCancelled) {
                currentTarget = null;
                mouseOutAction();
            }

            if (e.type === 'mouseover') {
                currentTarget = e.target;

                setTimeout(() => {
                    if (e.target === currentTarget && !hasCancelled) {
                        mouseOverAction();
                    }
                }, debounce || 200);
            }
        },
        () => {
            hasCancelled = true;
        }
    ];
}
