import React from 'react';
import Styled from 'styled-components';

const Wrapper = Styled.div<{fadeInTime?: string; noMargin?: boolean}>`
    margin: ${(p) => (p.noMargin ? '0' : '6rem 0')};
    text-align: center;
    animation: LoaderFadeIn ${({fadeInTime = '2s'}) => fadeInTime};

    @keyframes LoaderFadeIn {
        0% {opacity: 0;}
        100% {opacity: 1;}
    }
`;

const Item = Styled.div`
    width: 1rem;
    height: 1rem;
    animation: LoaderBounce 1s infinite;
    background-color: ${(p) => p.theme.colors.background};
    border-radius: 100%;
    border: 2px solid ${(p) => p.theme.colors.outline};
    display: inline-block;
    margin: .5rem .15rem;
    position: relative;
    top: 4px;

    @keyframes LoaderBounce {
        0% {transform: translateY(0);}
        25% {transform: translateY(-16px);}
        50% {transform: translateY(3px);}
        100% {transform: translateY(0);}
    }

    &:nth-child(2) {
        animation-delay: .15s;
    }

    &:nth-child(3) {
        animation-delay: .3s;
    }
`;

export default function Loader({fadeInTime, noMargin}: {fadeInTime?: string; noMargin?: boolean}) {
    return (
        <Wrapper data-testid="Loader" fadeInTime={fadeInTime} noMargin={noMargin}>
            <Item />
            <Item />
            <Item />
        </Wrapper>
    );
}
