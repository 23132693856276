import React from 'react';
import {Text} from 'bigdatr-style';
import {TableKeyValue} from 'bigdatr-style';
import {Box} from 'bigdatr-style/layout';
import {Flex} from 'bigdatr-style/layout';

type Props = {
    inTable: Record<string, any>;
    children: any;
    outButton?: any;
};

export default function InOutForm(props: Props) {
    return (
        <Box>
            <Box mb={2}>
                <Text textStyle="heading3">In</Text>
            </Box>
            <TableKeyValue data={props.inTable} />
            <Flex mt={3} mb={2} justifyContent="space-between">
                <Text textStyle="heading3">Out</Text>
                {props.outButton}
            </Flex>
            {props.children}
        </Box>
    );
}
