import React from 'react';
import {MappingFormProps} from '../MappingUpdateStructure';
import pick from 'unmutable/pick';
import Mapping from '../data/Mapping';
import {DropdownFilter} from 'bigdatr-style';
import Term from '~/affordance/Term';
import InOutForm from '~/affordance/InOutForm';
import {SchemaItem} from 'bigdatr-style/src/affordance/Table';

export const update = (data: Mapping) => ({
    payload: pick(['id', 'bigdatrPublicationId', 'note', 'status'])(data),
    query: `
        mutation MappingUpdate($payload: OztamChannelMappingMutation!) {
            mapping {
                mappingUpdate: oztamChannelMappingUpdate(mutation: $payload) {
                    id
                    type: __typename
                    createdAt
                    status
                    note
                    oztamChannelName
                    oztamRegionName
                    bigdatrPublicationId
                    bigdatrPublicationName
                }
            }
        }
    `
});

export const queryFragment = () => `
    ... on OztamChannelMapping {
        oztamChannelName
        oztamRegionName
        bigdatrPublicationId
        bigdatrPublicationName
    }
`;

export const choiceType = 'oztamChannelChoices';
export const choiceFragment = () => `
    ... on OztamChannelPublicationChoice {
        bigdatrPublicationId
        bigdatrPublicationName
    }
`;

export const tableSchema = (): Array<SchemaItem<Mapping>> => {
    return [
        {
            heading: 'Oztam Channel',
            key: 'oztamChannelName',
            value: (mapping) => mapping.oztamChannelName,
            render: (mapping) => <Term>{mapping.oztamChannelName}</Term>
        },
        {
            heading: 'Oztam Region',
            key: 'oztamRegionName',
            value: (mapping) => mapping.oztamRegionName,
            render: (mapping) => <Term>{mapping.oztamRegionName}</Term>
        },
        {
            heading: 'BigDatr Publication',
            key: 'bigdatrPublicationName',
            value: (mapping) => mapping.bigdatrPublicationName,
            render: (mapping) => <Term>{mapping.bigdatrPublicationName}</Term>
        }
    ];
};

export const Form: React.FC<MappingFormProps<Mapping>> = (props) => {
    const {mapping, onChange} = props;
    const {choices} = props;
    const id = (item: Mapping) => item.bigdatrPublicationId;
    const label = (item: Mapping) => item.bigdatrPublicationName;
    const {oztamChannelName, oztamRegionName} = mapping;

    return (
        <InOutForm inTable={{'Oztam Chanel': oztamChannelName, 'Oztam Region': oztamRegionName}}>
            <DropdownFilter
                control="Select Publication"
                successAction="Select"
                value={mapping}
                onChange={(next) => onChange({...next})}
                multi={false}
                id={id}
                label={label}
                options={choices}
            />
        </InOutForm>
    );
};
