import User from '../core/User';
import Team from '../team/Team';

export type TeamMemberRole = 'MEMBER' | 'OWNER';
export default class TeamMember {
    userId: string;
    teamId: string;
    role: TeamMemberRole;
    status: string;
    user: User;
    createdAt: string;
    updatedAt: string;
    team: Team;

    constructor(input: Partial<TeamMember>) {
        for (const key in input) {
            this[key] = input[key];
        }
    }

    get id() {
        return `${this.teamId}_${this.userId}`;
    }

    get roleLabel() {
        return {
            MEMBER: 'Member',
            OWNER: 'Owner'
        }[this.role];
    }
}
