import React from 'react';
import Clickable from './Clickable';
import Styled from 'styled-components';
import {StyledProps, Flex} from '../../layout';
import Text from './Text';
import type {LinkProps} from 'react-router-dom';
import type {ClickableProps} from './Clickable';

type Props = StyledProps & {
    to?: LinkProps<any>['to'];
    onClick?: Function;
    href?: string;
    target?: string;
    variant?: 'muted' | 'copy' | 'inherit';
};

const Link = Styled(Clickable)<Props>`${(props) => {
    switch (props.variant) {
        default:
            return `
                color: ${props.theme.colors.link};
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            `;

        case 'muted':
            return `
                color: ${props.theme.colors.muted};
                text-decoration: none;

                &:hover {
                    color: ${props.theme.colors.foreground2};
                    text-decoration: underline;
                }
        `;

        case 'copy':
            return `
                color: ${props.theme.colors.foreground};
                text-decoration: none;

                &:hover {
                    color: ${props.theme.colors.muted};
                    text-decoration: underline;
                }
        `;
    }
}}`;

export default Link;

export function LinkIcon(props: ClickableProps & {icon: React.ReactNode; children: any}) {
    const {icon, children, ...linkProps} = props;
    return (
        <Link {...linkProps}>
            <Flex alignItems="center">
                {icon}
                <Text ml={2}>{children}</Text>
            </Flex>
        </Link>
    );
}
