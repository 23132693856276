import type {ThemeType, ThemeConfig} from '../Theme';
import baseTheme from '../Theme';
import {DarkTheme as darkColors} from 'bigdatr-theme';

export default function DarkTheme(config: ThemeConfig): ThemeType {
    return {
        ...baseTheme(config),
        isDark: true,
        name: 'Dark',
        borders: {
            outline: `2px solid ${darkColors.outline}`,
            table: `2px solid ${darkColors.outline}`,
            tableThin: `1px solid ${darkColors.outline}`,
            cutout: `2px dashed ${darkColors.outline}`,
            error: `2px solid ${darkColors.red}`
        },
        colors: {...darkColors}
    };
}
