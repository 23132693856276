export default class MappingType {
    type: string;
    incompleteCount: number;
    totalCount: number;

    constructor(data: Omit<MappingType, 'label' | 'to'>) {
        this.type = data.type;
        this.incompleteCount = data.incompleteCount;
        this.totalCount = data.totalCount;
    }

    get label() {
        const labelMap = {
            similarwebDomainMapping: 'Similar Web',
            smiIndustryMapping: 'SMI Industry',
            smiBrandMapping: 'SMI Brand',
            smiMediaTypeMapping: 'SMI Media Type',
            gfkStationMapping: 'GFK Station',
            admonChannelMapping: 'Admon Channel',
            oztamChannelMapping: 'Oztam Channel',
            adClarityAdvertiserIndustryMapping: 'AdClarity Advertiser Industry'
        };
        return labelMap[this.type] || this.type;
    }
}
