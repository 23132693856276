export type CampaignInput = {
    id?: string;
    name: string;
};

export default class Campaign {
    private data: CampaignInput;
    type = 'campaign' as const;

    constructor(input: CampaignInput) {
        this.data = input;
    }

    get id() {
        return this.data.id || this.data.name;
    }

    get label() {
        return this.data.name;
    }
}
