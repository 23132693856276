import {useState, useCallback, useEffect} from 'react';
import LocalStorage from './LocalStorage';

export default function useLocalStorage<T = string>(
    key: string,
    defaultValue?: T,
    options: {
        /**
         * sometimes its really bad to sync local storage. specific use-case is the creative order
         * by for data entry.
         *
         * if you're doing some creative exploration in multiple tabs, and in one tab you want order
         * A, while in the other tab order B, you would not be able to do this because the listener
         * would modify your query in the other tab as well */
        syncChanges?: boolean;
    } = {syncChanges: true}
) {
    let initialValue = LocalStorage.getItem<T>(key) ?? defaultValue;
    if (typeof initialValue === 'undefined' && typeof defaultValue !== 'undefined') {
        initialValue = defaultValue;
        LocalStorage.setItem(key, defaultValue);
    }

    const [value, setValue] = useState<T | null>(initialValue ?? null);

    const onChange = useCallback((next: T | null) => {
        try {
            if (next == null) {
                LocalStorage.removeItem(key);
            } else {
                LocalStorage.setItem(key, next);
            }
        } catch (e) {
            // setItem can sometimes throw if the storage is full.
            // do nothing as memory state will still be updated
        }
        setValue(next);
    }, []);

    useEffect(() => {
        if (options?.syncChanges) {
            return LocalStorage.listen(() => setValue(LocalStorage.getItem(key)));
        }
    }, [key, options?.syncChanges]);

    return [value, onChange] as const;
}
