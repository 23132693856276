import {useRef} from 'react';
import {proxy, subscribe, useSnapshot} from 'valtio';
import LocalStorage from './LocalStorage';

export default function createLocalStorageStore<T extends object>(key: string, storeConfig: T) {
    const getState = () => LocalStorage.getItem<T>(key) || storeConfig;
    const store = proxy({...storeConfig, ...getState()});

    // Persist changes back to localstorage
    subscribe(store, () => LocalStorage.setItem(key, store));
    // also listen to other changes in other tabs
    LocalStorage.listen(() => Object.assign(store, getState()));

    // React hook
    function useStore() {
        const storeRef = useRef(store).current;
        const snapshot = useSnapshot(store);
        return [snapshot, storeRef] as const;
    }

    return {
        useStore,
        store
    };
}
