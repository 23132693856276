import {ArraySchema, ObjectSchema, EntitySchema} from 'react-enty';
import {Schemas, ClassSchema} from 'bigdatr-style';
import Mapping from '~/mapping/data/Mapping';
import MappingType from '~/mapping/data/MappingType';

// Helpers
const id = (key: string) => (data: Object) => data?.[key] || 'NONE';

// Entities

const mappingList = new EntitySchema('MappingList');
const mappingItem = new EntitySchema('MappingItem', {
    shape: new ClassSchema(Mapping, {})
});
const mappingType = new EntitySchema('MappingType', {
    id: id('type'),
    shape: new ClassSchema(MappingType, {})
});
const mappingTypeList = new EntitySchema('mappingTypeList', {id: () => 'globalMappingTypeList'});

// Relationships
mappingList.shape = new ObjectSchema({
    items: new ArraySchema(mappingItem)
});

mappingTypeList.shape = new ArraySchema(mappingType);

// Main Schema
export default new ObjectSchema({
    ...Schemas,
    mapping: new ObjectSchema({
        mappingType,
        mappingTypeList,
        mappingList,
        mappingItem,
        mappingUpdate: mappingItem
    })
});

//
// Creative Tagging Schema

export const creativeItemSchema = new EntitySchema('creativeItem');
export const creativeListSchema = new ObjectSchema({items: new ArraySchema(creativeItemSchema)});

//
// Releases
export const releaseItemSchema = new EntitySchema('release', {shape: new ObjectSchema({})});
export const releaseListSchema = new ObjectSchema({items: new ArraySchema(releaseItemSchema)});

//
// Bespoke schemas, we always have only 1 of each at any given time
export const liveReleaseItemSchema = new EntitySchema('release', {
    id: () => 'liveRelease',
    shape: new ObjectSchema({})
});

// builds
export const buildItemSchema = new EntitySchema('build');

//
// Changesets
export const changesetItemSchema = new EntitySchema('changeset');
export const changesetListSchema = new ObjectSchema({items: new ArraySchema(changesetItemSchema)});
export const changesetSelectionItem = new EntitySchema('changesetSelection', {
    shape: new ObjectSchema({})
});
export const changesetSelectionList = new ArraySchema(changesetSelectionItem);

changesetItemSchema.shape = new ObjectSchema({selections: changesetSelectionList});
