import {ThirdParty} from './Analytics';

export default class Hubspot implements ThirdParty {
    chat() {
        window.HubSpotConversations && window.HubSpotConversations.widget.open();
    }
    reloadChat() {
        window.HubSpotConversations && window.HubSpotConversations.widget.refresh();
    }

    trackError() {}
    trackEvent() {}
    identify() {}
    page() {}
}
