import type {LDClient} from 'launchdarkly-js-client-sdk';
import {AnalyticsIdentifyProps, ThirdParty} from './Analytics';

export default class LaunchDarkly implements ThirdParty {
    private _client: LDClient;
    private onSuccess: () => void;

    constructor(lDClient: LDClient, onSuccess: () => void) {
        this._client = lDClient;
        this.onSuccess = onSuccess;
    }

    getContext() {
        return this._client.getContext();
    }

    isAnonymousContext() {
        const context = this.getContext();
        if (context == null) return true;
        return this._client.getContext()?.anonymous === true;
    }

    setStreaming(shouldStream: boolean) {
        this._client.setStreaming(shouldStream);
    }

    identify(user: AnalyticsIdentifyProps) {
        this._client
            .identify({
                kind: 'user',
                key: user.id,
                id: user.id,
                name: user.name,
                email: user.email,
                custom: {
                    hasMediaValue: user?.hasMediaValue,
                    hasCreative: user?.hasCreative,
                    teamName: user?.teamName,
                    teamId: user?.teamId,
                    release: user.release,
                    isStaff: user.isStaff,
                    createdAt: user.createdAt
                }
            })
            .then(() => this.onSuccess());
    }

    trackError() {}
    trackEvent() {}
    page() {}
    chat() {}
}
