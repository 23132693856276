import * as smiBrandMapping from './SmiBrandMappingForm';
import * as smiIndustryMapping from './SmiIndustryMappingForm';
import * as smiMediaTypeMapping from './SmiMediaTypeMappingForm';
import * as gfkStationMapping from './GfkStationMappingForm';
import * as admonChannelMapping from './AdmonChannelMappingForm';
import * as oztamChannelMapping from './OztamChannelMappingForm';

import {SchemaItem} from 'bigdatr-style/src/affordance/Table';
import Mapping from '../data/Mapping';
import {MappingFormProps} from '../MappingUpdateStructure';

export type MappingTypeForm = {
    Form: React.ComponentType<MappingFormProps>;
    queryFragment: () => string;
    tableSchema: () => Array<SchemaItem<Mapping>>;
    update: (data: unknown) => {payload: unknown; query: string};

    // Optional
    choiceFragment?: () => string;
    choiceType?: string;
    Error?: React.FC<any>;
};

const mappingTypes: Record<string, MappingTypeForm> = {
    smiBrandMapping,
    smiIndustryMapping,
    smiMediaTypeMapping,
    gfkStationMapping,
    admonChannelMapping,
    oztamChannelMapping
};

export default mappingTypes;
