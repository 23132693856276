import Team from './Team';

export default class TeamInvite {
    createdAt: string;
    id: string;
    inviter: {id: string};
    role: 'MEMBER' | 'OWNER';
    status: 'PENDING' | 'ACCEPTED' | 'DELETED';
    team: Team;
    username: string;

    constructor(input: Partial<TeamInvite>) {
        for (const key in input) {
            this[key] = input[key];
        }
    }

    get roleLabel() {
        return {
            MEMBER: 'Member',
            OWNER: 'Owner'
        }[this.role];
    }

    get statusLabel() {
        return {
            PENDING: 'Invited',
            ACCEPTED: 'Accepted',
            DELETED: 'Deleted'
        }[this.status];
    }

    get statusColor() {
        return {
            PENDING: 'orange',
            ACCEPTED: 'green',
            DELETED: 'red'
        }[this.status];
    }
}
