import {useCallback} from 'react';
import ReconnectingWebsocket from 'reconnecting-websocket';
import {OAuthPost} from './OAuth';

type Config = {
    accessToken: string | null;
    refreshToken: string | null;
    onUpdateTokens: (tokens: {accessToken: string; refreshToken: string}) => void;
    onLogout: (redirectUrl: string) => void;
};

//
// @TODO add team id if we add this to other bigdatr apps
export default function useAuthorizedWebsocket(config: Config) {
    let accessToken = config.accessToken;
    let refreshToken = config.refreshToken;

    async function refreshTokenRequest() {
        const next = await OAuthPost('refreshToken', refreshToken);
        accessToken = next.accessToken;
        refreshToken = next.refreshToken;
        config.onUpdateTokens({accessToken, refreshToken});
    }

    return useCallback(
        (url) => {
            let retryCount = 0;
            const websocket = new ReconnectingWebsocket(`${url}?access_token=${accessToken}`);

            // Listen for general errors in the websocket connection
            // and try to refresh the token once before logging out
            websocket.addEventListener('error', () => {
                if (retryCount < 1) {
                    retryCount++;
                    // The types don't like an async function, so using a promise here.
                    refreshTokenRequest().then(() => {
                        // @ts-ignore the libary provides no api to update the url
                        websocket._url = `${url}?access_token=${accessToken}`;
                        websocket.reconnect();
                    });
                } else {
                    websocket.close();
                }
            });
            return websocketData(websocket);
        },
        [accessToken, refreshToken]
    );
}

export async function* websocketData(
    websocket: ReconnectingWebsocket
): AsyncGenerator<unknown, void, void> {
    for await (const event of websocketEvents(websocket)) {
        yield event.data;
    }
}

function websocketEvents(websocket: ReconnectingWebsocket) {
    let done = false;
    const values: Array<IteratorResult<MessageEvent> | PromiseLike<never>> = [];
    const resolvers: Array<(value: IteratorResult<MessageEvent> | PromiseLike<never>) => void> = [];

    const close = () => {
        done = true;
        while (resolvers.length > 0)
            (resolvers.shift() as typeof resolvers[number])({value: undefined, done: true});
    };

    const push = (data: {value: MessageEvent; done: boolean} | PromiseLike<never>) => {
        if (done) return;
        if (resolvers.length > 0) {
            (resolvers.shift() as typeof resolvers[0])(data);
        } else {
            values.push(data);
        }
    };

    const pushError = (error: unknown) => {
        push(thenableReject(error));
        close();
    };

    const pushEvent = (event: MessageEvent) => push({value: event, done: false});

    const next = (): Promise<IteratorResult<MessageEvent, unknown>> => {
        if (values.length > 0) return Promise.resolve(values.shift() as typeof values[0]);
        if (done) return Promise.resolve({value: undefined, done: true});
        return new Promise((resolve) => resolvers.push(resolve));
    };

    const initSocket = () => {
        websocket.addEventListener('close', close);
        websocket.addEventListener('error', pushError);
        websocket.addEventListener('message', pushEvent);
    };

    if (websocket.readyState === WebSocket.CONNECTING) {
        websocket.addEventListener('open', () => initSocket());
    } else {
        initSocket();
    }

    const generator = {
        [Symbol.asyncIterator]: () => generator,
        next,
        throw: async (value: unknown) => {
            pushError(value);
            if (websocket.readyState === WebSocket.OPEN) websocket.close();
            return next();
        },
        return: async () => {
            close();
            if (websocket.readyState === WebSocket.OPEN) websocket.close();
            return next();
        }
    };

    return generator;
}

function thenableReject<T>(error: T): PromiseLike<never> {
    return {
        then: (
            resolve: (value: never) => PromiseLike<never>,
            reject: (error: T) => PromiseLike<never>
        ) => reject(error)
    };
}
