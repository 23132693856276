import {useMemo} from 'react';

export type UseDropdownFilterBubblerConfig<T> = {
    open: boolean;
    search: string;
    options: Array<T>;
    optionId: (option: T) => string;
    selectedOptions: Array<T>;
    pagination?: {
        queryKey: string;
    };
};

const useDropdownFilterBubbler = <T>({
    open,
    search,
    options,
    optionId,
    selectedOptions,
    pagination
}: UseDropdownFilterBubblerConfig<T>) => {
    const optionsToBubble = useMemo(() => {
        // only bubble selected options when not in search mode.
        if (search) return new Array<T>();

        return [...selectedOptions];
    }, [open, search, pagination ? pagination.queryKey : options]);

    const bubbledOptions = useMemo(() => {
        const bubbledIds = optionsToBubble.map((selectedOption) => optionId(selectedOption));

        return optionsToBubble.concat(
            options.filter((option) => !bubbledIds.includes(optionId(option)))
        );
    }, [optionsToBubble, options]);

    return {
        options: bubbledOptions
    };
};

export default useDropdownFilterBubbler;
