import React from 'react';
import ReactModal from 'react-modal';
import useHotkey from '../util/useHotkey';
import Styled from 'styled-components';
import {IconCross} from '../../icon';
import {breakpoint} from '../core/themes/themeGetters';
import Clickable from './Clickable';

export const Modal = Styled(
    React.forwardRef((props: Props<any>, ref: React.Ref<any>) => {
        const {className, ...otherProps} = props;
        return (
            <ReactModal
                className="Modal"
                overlayClassName="Overlay"
                portalClassName={className}
                {...otherProps}
                ref={ref}
            />
        );
    })
)`
    &[class*='--after-open'] {
        overflow: hidden;
    }


    & .Overlay {
        top: 0;
        margin: auto;
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: ${(p) => p.theme.zIndices.Overlay};
        overflow-y: scroll;
        background-color: ${(p) => p.theme.colors.background};

        @media (min-width: ${breakpoint('md')}) {
            overflow-y: ${(p) => (p.noOverlayOverflow ? 'hidden' : 'auto')};
            align-items: ${(p) => p.alignItems || 'center'};
            background: ${({inverted, theme}) =>
                inverted
                    ? `linear-gradient(0deg, ${theme.colors.background} 0%, ${theme.colors.background}cc 100%)`
                    : `rgba(0, 0, 0, 0.8)`};
            display: flex;
            justify-content: center;
        }
    }

    & .Modal {
        flex-grow: 1;
        max-height: 100vh;
        width: 100%;
        max-width: ${(p) => p.maxWidth || '80rem'};
        margin: 2rem;

        &:focus {
            outline: none;
        }
    }
`;

const Close = Styled(Clickable)`
    position: fixed;
    right: .5rem;
    top: .5rem;
    cursor: pointer;
    z-index: 1;
    padding: .25rem;
    background: ${(p) => p.theme.colors.background};
    border-radius: 4rem;
    box-shadow: 0 0 .75rem ${(p) => p.theme.colors.foreground};

    @media (min-width: ${breakpoint('md')}) {
        right: 1rem;
        top: 1rem;
    }
`;

// OnRequestClose must explicitly be provided as we don't want to unintentionally make a modal unclosable..
type OnRequestClose = (() => any) | undefined;

type Props<C extends OnRequestClose> = {
    isOpen: boolean;
    onRequestClose: C;
    showCross?: boolean;
    width?: string;
    maxWidth?: string;
    noOverlayOverflow?: boolean;
    alignItems?: string;
    inverted?: boolean;
    children: (events: {onRequestClose: C}) => React.ReactNode;
    className?: string;
};

export default function Overlay<C extends OnRequestClose>(props: Props<C>) {
    const {children} = props;
    const {isOpen} = props;
    const {maxWidth} = props;
    const {onRequestClose} = props;
    const {alignItems} = props;
    const {showCross = true} = props;

    const ref = useHotkey({
        escape: () => onRequestClose?.()
    });

    return (
        <Modal
            ref={ref}
            isOpen={isOpen}
            ariaHideApp={false}
            onRequestClose={onRequestClose}
            maxWidth={maxWidth}
            alignItems={alignItems}
            inverted={props.inverted}
            noOverlayOverflow={props.noOverlayOverflow}
        >
            {/* @ts-ignore for the life of me i can't match the type signature */}
            <>
                {onRequestClose && showCross && (
                    <Close onClick={onRequestClose} data-testid="Overlay_close">
                        <IconCross size="2rem" />
                    </Close>
                )}
                {isOpen && children({onRequestClose})}
            </>
        </Modal>
    );
}
