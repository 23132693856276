import {IconCheck, IconCross} from '../../icon';
import {Flex, Box} from '../../layout';
import Paper from './Paper';
import React, {useLayoutEffect, useState} from 'react';
import {Transition} from 'react-transition-group';

type Props = {
    type: 'success' | 'error';
    children: React.ReactNode;
    onRequestClose: () => void;
    timer: number;
};

const transitionStyles = {
    entering: '0',
    entered: '0',
    exiting: '-4rem',
    exited: '-4rem'
};

export default function Toast({type, onRequestClose, children, timer}: Props) {
    const Icon = type === 'success' ? IconCheck : IconCross;
    const [inProp, setIn] = useState(false);

    useLayoutEffect(() => {
        let toastExitDelay = timer;
        if ('Cypress' in window) toastExitDelay = 150;
        const enter = setTimeout(() => setIn(true), 10);
        const delay = setTimeout(() => setIn(false), toastExitDelay);
        return () => {
            clearTimeout(enter);
            clearTimeout(delay);
        };
    }, []);

    return (
        <Transition in={inProp} timeout={500} onExited={onRequestClose}>
            {(state: keyof typeof transitionStyles) => (
                <Flex
                    left="50%"
                    style={{
                        transform: `translateX(-50%) translateY(${transitionStyles[state]})`,
                        transition: 'transform 160ms'
                    }}
                    justifyContent="center"
                    top=".75rem"
                    position="fixed"
                    zIndex="Toast"
                >
                    <Paper
                        backgroundColor={type === 'success' ? 'green' : 'red'}
                        color="white"
                        round
                        px={3}
                        py={2}
                        fontWeight="bold"
                    >
                        <Flex alignItems="center">
                            <Icon size="1.5rem" color="white" />
                            <Box ml={3}>{children}</Box>
                        </Flex>
                    </Paper>
                </Flex>
            )}
        </Transition>
    );
}
