export type PublicationInput = {id?: string; name: string};

export default class Publication {
    private data: PublicationInput;
    type = 'publication' as const;

    constructor(input: PublicationInput) {
        this.data = input;
    }

    get id() {
        return this.data.id || this.data.name;
    }

    get label() {
        return this.data.name;
    }
}
