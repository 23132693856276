import setIn from 'unmutable/setIn';
import update from 'unmutable/update';

// @Intent
// This is an extendable class that allows classes to fully
// work with unmutable. It's typesafeish. If you don't need to put the model
// in a parcel don't extend this class.
//
// Known caveats and hacks:
// 1. only get is typesafe, I removed the notSetValue to make it safe.
//    if this has problems we can add it back in and just dot notation for typesafety
// 2. ((this as unknown) as A) is intentional to cool typescript from complaining too much about
//    things going into the unit function
// 3. ts-ignore on finding the constructor in the unit function

export default class UnmutableCompatible<A extends Record<string, any>> {
    constructor() {
        Object.defineProperty(this, '__UNMUTABLE_COMPATIBLE__', {enumerable: false, value: true});
    }
    unit(data: A): A {
        // @ts-ignore
        return new this.constructor({...data});
    }
    toObject() {
        return {...this};
    }
    toJSON() {
        return this.toObject();
    }
    has(key: keyof A): boolean {
        return key in this;
    }
    get<K extends keyof A>(key: K): A[K] {
        const clone = this.clone();
        return clone[key];
    }
    getIn<Path extends string[], N>(keyPath: Path, notSetValue?: N) {
        let value = this.clone();
        for (const key of keyPath) {
            if (!(key in value)) return notSetValue;
            value = value[key];
        }
        return value;
    }
    set(key: keyof A, value: A[keyof A]) {
        return this.unit(({...this, [key]: value} as unknown) as A);
    }
    setIn(keyPath: string[], value: any) {
        return this.unit(setIn(keyPath, value)({...this}));
    }
    delete(key: keyof A) {
        const clone = this.clone();
        delete clone[key];
        return this.unit(clone);
    }
    entries() {
        return new Map(Object.entries({...this})).entries();
    }
    merge(next: Partial<A>) {
        return this.unit(({...this.toObject(), ...next} as unknown) as A);
    }
    update(...args) {
        return this.unit(update(...args)({...this}));
    }
    clear() {
        return this.unit(({} as unknown) as A);
    }
    clone(): A {
        return this.unit(({...this} as unknown) as A);
    }
    count() {
        return [...this.entries()].length;
    }
}
