import {safeLazyImport} from 'bigdatr-style';
import {LazyRoute, Parse, BoringRoute} from 'bigdatr-style';
import type {Presets, Query} from '~/filters/data/parseFilters';

const RuleEditRoute = safeLazyImport(() => import('~/pages/RuleEditRoute'));
const RuleCreateRoute = safeLazyImport(() => import('~/pages/RuleCreateRoute'));
const RuleListRoute = safeLazyImport(() => import('~/pages/RuleListRoute'));
const RuleApplyToHistoricalCreatives = safeLazyImport(
    () => import('~/pages/RuleApplyToHistoricalCreatives')
);
const RuleHistoryRoute = safeLazyImport(() => import('~/pages/RuleHistoryRoute'));
const RuleCreativesRoute = safeLazyImport(() => import('~/pages/RuleCreativesRoute'));

export default {
    ruleEdit: LazyRoute<{id: string; tab?: string}>({
        path: '/rule/:id',
        parse: {
            id: Parse.param.string((x) => x as string),
            tab: Parse.query.string((x) => x || 'filters')
        },
        component: RuleEditRoute
    }),
    ruleApplyToHistoricalCreatives: LazyRoute<{id: string; filter?: Query}>({
        path: '/rule/:id/apply-to-historical-creatives',
        parse: {
            id: Parse.param.string((x) => x as string),
            filter: Parse.query.JSON((x) => x as Query)
        },
        component: RuleApplyToHistoricalCreatives
    }),
    ruleHistory: LazyRoute<{id: string}>({
        path: '/rule/:id/history',
        parse: {
            id: Parse.param.string((x) => x as string)
        },
        component: RuleHistoryRoute
    }),
    ruleCreatives: LazyRoute<{id: string; filters?: Query; presets?: Presets}>({
        path: '/rule/:id/creatives',
        parse: {
            id: Parse.param.string((x) => x as string),
            filters: Parse.query.JSON((x) => x as Query),
            presets: Parse.query.JSON((x) => x as Presets)
        },
        component: RuleCreativesRoute
    }),
    ruleList: BoringRoute({
        path: '/rule/list',
        component: RuleListRoute
    }),
    ruleCreate: LazyRoute<{filters?: Query; tab?: string}>({
        path: '/rule/create',
        parse: {
            filters: Parse.query.JSON((x) => x as Query),
            tab: Parse.query.string((x) => x || 'filters')
        },
        component: RuleCreateRoute
    })
};
