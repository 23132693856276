import React from 'react';
import {Box} from 'bigdatr-style/layout';
import InOutForm from '~/affordance/InOutForm';
import {DropdownFilter} from 'bigdatr-style';
import {MappingFormProps} from '../MappingUpdateStructure';
import Mapping from '../data/Mapping';
import pick from 'unmutable/pick';
import Term from '~/affordance/Term';
import {SchemaItem} from 'bigdatr-style/src/affordance/Table';

export const update = (data: Mapping) => ({
    payload: pick(['id', 'bigdatrIndustries', 'note', 'status'])(data),
    query: `
        mutation MappingUpdate($payload: SmiIndustryMappingMutation!) {
            mapping {
                mappingUpdate: smiIndustryMappingUpdate(mutation: $payload) {
                    id
                    type: __typename
                    createdAt
                    status
                    note
                    smiProductCategory
                    smiProductSubcategory
                    bigdatrIndustries
                }
            }
        }
    `
});

export const choiceType = 'smiIndustryChoices';
export const choiceFragment = () => `
    ... on SmiIndustryChoice {
        bigdatrIndustry
    }
`;

export const queryFragment = () => `
    ... on SmiIndustryMapping {
        smiProductCategory
        smiProductSubcategory
        bigdatrIndustries
    }
`;

export const tableSchema = (): Array<SchemaItem<Mapping>> => {
    return [
        {
            key: 'smiProductCategory',
            heading: 'Product Category',
            value: (ii: Mapping) => ii.smiProductCategory,
            render: (ii: Mapping) => <Term>{ii.smiProductCategory}</Term>
        },
        {
            key: 'smiProductSubcategory',
            heading: 'Product Sub-category',
            value: (ii: Mapping) => ii.smiProductSubcategory,
            render: (ii: Mapping) => <Term>{ii.smiProductSubcategory}</Term>
        },
        {
            key: 'bigdatrIndustries',
            heading: 'BigDatr Industries',
            value: (ii: Mapping) => ii.bigdatrIndustries?.join(', '),
            render: (ii: Mapping) =>
                ii.bigdatrIndustries ? ii.bigdatrIndustries.join(', ') : 'Empty'
        }
    ];
};

export const Form: React.FC<MappingFormProps<Mapping>> = (props) => {
    const {mapping, choices, onChange} = props;
    const {smiProductSubcategory, smiProductCategory} = mapping;
    const value = mapping.bigdatrIndustries || [];

    return (
        <InOutForm
            inTable={{
                'Product Category': smiProductCategory,
                'Product Subcategory': smiProductSubcategory
            }}
        >
            {value.map((name: string) => (
                <Box key={name}>{name}</Box>
            ))}
            <DropdownFilter<string>
                control="Select Industries"
                successAction="Select"
                returnAll={true}
                multi
                onChange={(bigdatrIndustries) => onChange({bigdatrIndustries})}
                options={choices.map((ii) => ii.bigdatrIndustry)}
                id={(_) => _}
                label={(_) => _}
                value={value}
            />
        </InOutForm>
    );
};
