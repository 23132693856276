import {Box} from '../../layout';
import styled from 'styled-components';
import {color} from 'styled-system';

type Props = {
    outlined?: boolean; // outline clashes with styled system
    cutout?: boolean;
    hover?: boolean;
    round?: boolean;
    card?: boolean;
    shadow?: boolean;
};

export default styled(Box)<Props>`
    background-color: ${(p) => p.theme.colors.background};
    ${color}

    ${(props) =>
        props.outlined &&
        `
        border: 2px solid ${props.theme.colors.outline};
    `}

    ${(props) =>
        props.cutout
            ? `
        border: 2px dashed ${props.theme.colors.outline};
        border-radius: .5rem;
    `
            : ''}

    ${(props) =>
        props.hover &&
        `
        box-shadow: 0 2px 4px rgba(0, 0, 0, .3);
        z-index: ${props.theme.zIndices.Paper};
        position: relative;
    `}

    ${(props) =>
        props.round &&
        `
        border-radius: .5rem;
    `}

    ${(props) =>
        props.card &&
        `
        border: 2px solid ${props.theme.colors.outline};
        box-shadow: 0 1rem 1rem -0.5rem rgba(0, 0, 0, .3);
        border-radius: .25rem;
    `}

    ${(props) =>
        props.shadow &&
        `
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    `}
`;
