import {useContext} from 'react';
import React from 'react';
import {ThemeContext, ThemeProvider as StyledProvider, createGlobalStyle} from 'styled-components';
import Themes from './themes/index';

const BackgroundStyles = createGlobalStyle`
    body {
        background-color: ${(props) => props.theme.colors.background};
        color: ${(props) => props.theme.colors.foreground};
        font-family: ${(props) => props.theme.fonts.copy};
        font-size: ${(p) => p.theme.fontSizes[2]}px;
        font-weight: ${(p) => p.theme.fontWeights.regular};
        line-height: 1.5;
    }
`;

export const useTheme = () => useContext(ThemeContext);

export function ThemeProvider(props: {
    children: React.ReactNode;
    name?: 'Light' | 'Dark';
    fontStack?: 'bigdatr' | 'system' | 'monospace';
    spacing?: 'normal' | 'cosy' | 'tight';
}) {
    const theme = Themes[props.name || 'Light']({
        fontStack: props.fontStack || 'bigdatr',
        spacing: props.spacing || 'normal'
    });
    return (
        <StyledProvider theme={theme}>
            <BackgroundStyles />
            {props.children}
        </StyledProvider>
    );
}
