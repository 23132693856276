import {createRequestHook, ObjectSchema} from 'react-enty';
import {
    changesetItemSchema,
    changesetListSchema,
    changesetSelectionItem,
    changesetSelectionList
} from '~/core/data/EntitySchema';
import {RpcRequest} from '~/core/data/Endpoints';

export const changesetCreate = createRequestHook({
    name: 'changesetCreate',
    request: RpcRequest('changesetCreate'),
    schema: changesetItemSchema
});

export const changesetGet = createRequestHook({
    name: 'changesetGet',
    request: RpcRequest('changesetGet'),
    schema: changesetItemSchema
});

export const changesetUpdate = createRequestHook({
    name: 'changesetUpdate',
    request: RpcRequest('changesetUpdate'),
    schema: changesetItemSchema
});

export const changesetSearch = createRequestHook({
    name: 'changesetSearch',
    request: RpcRequest('changesetSearch'),
    schema: changesetListSchema
});

export const changesetPrepare = createRequestHook({
    name: 'changesetPrepare',
    schema: changesetItemSchema,
    request: RpcRequest('changesetPrepare')
});

export const changesetPublish = createRequestHook({
    name: 'changesetPublish',
    schema: changesetItemSchema,
    request: RpcRequest('changesetPublish')
});

export const changesetHistory = createRequestHook({
    name: 'changesetHistory',
    request: RpcRequest('changesetHistory')
});

//
// Changeset selections
export const changesetSelectionGetAll = createRequestHook({
    name: 'changesetSelectionGetAll',
    request: RpcRequest('changesetSelectionGetAll'),
    schema: changesetSelectionList
});

export const changesetSelectionCreateMany = createRequestHook({
    name: 'changesetSelectionCreateMany',
    request: RpcRequest('changesetSelectionCreateMany'),
    schema: new ObjectSchema({
        selections: changesetSelectionList,
        changeset: changesetItemSchema
    })
});

export const changesetSelectionUpdate = createRequestHook({
    name: 'changesetSelectionUpdate',
    request: RpcRequest('changesetSelectionUpdate'),
    schema: changesetSelectionItem
});

export const changesetSelectionUpdateMany = createRequestHook({
    name: 'changesetSelectionUpdateMany',
    request: RpcRequest('changesetSelectionUpdateMany'),
    schema: changesetSelectionList
});

export const changesetSelectionDelete = createRequestHook({
    name: 'changesetSelectionDelete',
    request: RpcRequest('changesetSelectionDelete'),
    schema: new ObjectSchema({
        selection: changesetSelectionItem,
        changeset: changesetItemSchema
    })
});

export const changesetSelectionDeleteMany = createRequestHook({
    name: 'changesetSelectionDeleteMany',
    request: RpcRequest('changesetSelectionDeleteMany'),
    schema: new ObjectSchema({
        selections: changesetSelectionList,
        changeset: changesetItemSchema
    })
});

//
// Changeset adjustments
export const adjustmentTemporaryEdit = createRequestHook({
    name: 'adjustmentTemporaryEdit',
    request: RpcRequest('adjustmentTemporaryEdit')
});
