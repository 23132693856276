import * as SentryBrowser from '@sentry/browser';
import {ThirdParty} from './Analytics';

export default class Sentry implements ThirdParty {
    constructor(config: {dsn: string; service: string; environment: string; release: string}) {
        const {dsn, service, environment, release} = config;
        SentryBrowser.init({dsn, release, environment});
        SentryBrowser.configureScope((scope) => {
            scope.setTag('service', service);
        });
    }

    identify(user: {id: string; name: string; email: string}) {
        SentryBrowser.configureScope((scope) => {
            scope.setUser({email: user.email});
        });
    }

    trackError(error: Error, errorInfo = {}) {
        SentryBrowser.withScope((scope) => {
            Object.keys(errorInfo).forEach((key) => {
                scope.setExtra(key, errorInfo[key]);
            });
            SentryBrowser.captureException(error);
        });
    }

    addBreadcrumb(category: string, message: string) {
        SentryBrowser.addBreadcrumb({category, message});
    }

    trackEvent() {}
    page() {}
    chat() {}
}
