import {ArraySchema, ObjectSchema, createRequestHook, EntitySchema} from 'react-enty';
import {Payload} from '~/core/data/EntityApi';
import {RpcRequest} from '~/core/data/Endpoints';

export const ruleSchema = new EntitySchema('Rule', {
    shape: new ObjectSchema({}),
    id: (r) => r?.id
});

export const ruleGet = createRequestHook({
    name: 'ruleGet',
    schema: ruleSchema,
    request: RpcRequest('ruleGet')
});

export const ruleUpsert = createRequestHook({
    name: 'ruleUpsert',
    schema: ruleSchema,
    request: async (payload: Payload<'ruleCreate'> | Payload<'ruleUpdate'>, meta) => {
        if ('id' in payload && payload.id) return RpcRequest('ruleUpdate')(payload, meta);
        return RpcRequest('ruleCreate')(payload, meta);
    }
});

export const ruleList = createRequestHook({
    name: 'ruleList',
    schema: new ObjectSchema({items: new ArraySchema(ruleSchema), pageInfo: new ObjectSchema({})}),
    request: RpcRequest('ruleList')
});

export const applyRuleToHistoricalCreatives = createRequestHook({
    name: 'applyRuleToHistoricalCreatives',
    schema: new ObjectSchema({}),
    request: RpcRequest('applyRuleToFilteredCreativesJob')
});

export const jobConfirm = createRequestHook({
    name: 'jobConfirm',
    schema: new ObjectSchema({}),
    request: RpcRequest('jobConfirm')
});
