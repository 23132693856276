import React from 'react';
import {FunctionComponent} from 'react';
import {Text} from 'bigdatr-style';

type Props = {
    children: string;
};

const Term: FunctionComponent<Props> = ({children}) => {
    // Replace mutli spaces, starting spaces, and ending spaces with a dot
    return (
        <Text>
            {(children || 'empty').replace(/( {2,})|(^ )|( $)/g, (s) => '·'.repeat(s.length))}
        </Text>
    );
};

export default Term;
