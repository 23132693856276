type Config<A> = {
    onChange: (newValue: Array<A>, option?: A) => void;
    options: Array<A>;
    value: Array<A>;
    multi?: boolean;
    id: (a: A) => string;
    onSelectAll?: (e: A) => void;
};

type Returns<A> = {
    allSelected: boolean;
    noneSelected: boolean;
    onSelectAll: (e) => void;
    onSelectNone: (e) => void;
    options: Array<{
        id: string;
        option: A;
        selected: boolean;
        onAdd: Function;
        onToggle: Function;
        onRemove: Function;
        onSelect: Function;
    }>;
};

export default function useSelect<A>(config: Config<A>): Returns<A> {
    const {id, value = new Array<A>(), options, onChange, multi = true, onSelectAll} = config;

    const noop = () => {
        //empty method
    };

    // Make value set
    const valueSet = new Set(value.map(config.id));

    // Make thunks
    const remove = (removeId) => () => {
        let removedItem;
        const newValue = new Array<A>();
        value.forEach((option) => {
            if (id(option) === removeId) {
                removedItem = option;
            } else {
                newValue.push(option);
            }
        });
        onChange(newValue, removedItem);
    };
    const add = (option) => () => onChange(value.concat(option), option);
    const select = (option) => () => onChange([option], option);

    // iterate over options to generate returns
    return {
        allSelected: valueSet.size === options.length,
        noneSelected: valueSet.size === 0,
        onSelectAll: onSelectAll ? onSelectAll : () => onChange(options),
        onSelectNone: () => onChange([]),
        options: options.map((option) => {
            const currentId = id(option);
            const selected = valueSet.has(currentId);
            const onToggle = selected ? remove(currentId) : multi ? add(option) : select(option);
            const onAdd = selected ? noop : onToggle;
            const onRemove = selected ? onToggle : noop;
            const onSelect = select(option);

            return {
                id: currentId,
                option,
                selected,
                onToggle,
                onAdd,
                onRemove,
                onSelect
            };
        })
    };
}
