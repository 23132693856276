import c from './LightTheme';

export default {
    ...c,

    foreground: c.grey7,
    foreground1: c.grey6,
    foreground2: c.grey5,
    foreground3: c.grey4,
    background: c.grey1,
    background1: c.grey2,
    background2: c.grey3,
    background3: c.grey4,
    backgroundHover: c.grey3,
    backgroundHoverPurple: '#2c2736',

    outline: c.grey2,
    outlineDisabled: c.grey3,
    active: c.purple100,
    activeAlt: c.blue100,
    diffAdd: c.green100,
    diffRemove: c.red100
};
