import superjson from 'superjson';

type ApiLike = Record<string, {handler: (...args: any[]) => Promise<any>}>;

/**
Creates a helper record to more easily use types in react views

@example
type Props = {data: Api['foo']['response']};
*/
export type CreateApiTypes<Api extends ApiLike> = {
    [K in keyof Api]: {
        payload: Parameters<Api[K]['handler']>[0];
        response: Awaited<ReturnType<Api[K]['handler']>>;
    };
};

/**
Create a requester based on an api. This removes a lot of boilerplate
types from the front end and hides the implementation of superjson.

Note: the rest type is pretty dodgy, and only used because enty passes
a meta object through each request function
*/
export function createRequester<Api extends ApiLike>(
    fn: (
        v: {method: keyof Api; payload: ReturnType<typeof superjson.serialize>},
        ...rest: any[]
    ) => any
) {
    return <M extends keyof Api>(method: M) => {
        return async (
            payload: Parameters<Api[M]['handler']>[0],
            ...rest: unknown[]
        ): Promise<ReturnType<Api[M]['handler']>> => {
            const data = await fn({method, payload: superjson.serialize(payload)}, ...rest);
            return superjson.deserialize<ReturnType<Api[M]['handler']>>(data);
        };
    };
}
