import {createContext, useContext} from 'react';
import Viewer from './Viewer';

const ViewerContext = createContext<Viewer | undefined>(undefined);

export const ViewerProvider = ViewerContext.Provider;

export function useViewerUnsafe() {
    return useContext(ViewerContext);
}

export function useViewer(): Viewer {
    const viewer = useViewerUnsafe();
    if (!viewer) throw new Error('Viewer used before it exists');
    return viewer;
}
