export type MediaOwnerInput = {
    name: string;
    id: string;
};
export default class MediaOwner {
    private data: MediaOwnerInput;
    type = 'mediaOwner' as const;

    constructor(input: MediaOwnerInput) {
        this.data = input;
    }

    get id() {
        return this.data.id;
    }

    get label() {
        return this.data.name;
    }
}
