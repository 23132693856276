import Styled from 'styled-components';
import React, {ReactNode} from 'react';
import Parcel from 'react-dataparcels';
import {Box} from '../../layout';
import {Flex} from '../../layout';
import {Hidden} from '../../layout';
import {Wrapper} from '../../layout';
import {IconChevronDown} from '../../icon';
import {Dropdown} from '../index';
import {DropdownList} from '../index';
import {Text} from '../index';
import {MenuItem} from '../index';
import {Clickable} from '../index';
import {space} from 'styled-system';
import {ThemedLogo} from '../index';
import Favicon from '../assets/favicon.png';

const NavigationLink = Styled(Clickable)`
    cursor: pointer;
    display: block;
    font-weight: ${(p) => p.theme.fontWeights.bold};
    line-height: 1;
    padding-left: .5rem;
    padding-right: .5rem;
    ${space}
`;

type Props = {
    items?: Array<{}>;
    dropdown?: ReactNode;
    dropdownTitle?: ReactNode;
    wrapper?: boolean;
    searchParcel?: Parcel<string>;
    homeUrl?: string;
    small?: boolean;
};
export default function Navigation(props: Props) {
    const {items = []} = props;
    const {dropdown} = props;
    const {small} = props;
    const {dropdownTitle} = props;
    const {wrapper = true} = props;
    const {homeUrl = '/'} = props;
    const WrapperElement = wrapper ? Wrapper : Box;

    const mainItems = items.map((props: Record<any, any>, key) => (
        <NavigationLink key={key} {...props} />
    ));

    const dropdownComponent = Boolean(dropdown) && (
        <Dropdown
            maxWidth={{md: '20rem'}}
            align="right"
            control={() => (
                <NavigationLink>
                    <Flex alignItems="center">
                        <Text mr={2}>{dropdownTitle}</Text>
                        <IconChevronDown size="1.25rem" />
                    </Flex>
                </NavigationLink>
            )}
            tooltip="Settings"
            menu={({onClose}) => (
                <Box color="foreground" onClick={onClose}>
                    <Box display={{md: 'none'}}>
                        <DropdownList>
                            {items.map((props: Record<any, any>, key) => (
                                <MenuItem key={key} {...props} />
                            ))}
                        </DropdownList>
                    </Box>
                    {dropdown}
                </Box>
            )}
        />
    );

    return (
        <WrapperElement>
            <Flex alignItems="center" p={small ? 2 : 3}>
                <Clickable to={homeUrl} mr={small ? 2 : 'auto'}>
                    {small ? <img height="24px" src={Favicon} /> : <ThemedLogo />}
                </Clickable>
                <Hidden
                    display={{_: small ? 'flex' : undefined, md: 'flex'}}
                    flexWrap="wrap"
                    gap={1}
                    mr={small ? undefined : 3}
                >
                    {mainItems}
                </Hidden>
                {dropdownComponent}
            </Flex>
        </WrapperElement>
    );
}
