import {LegacyGraphql, Meta} from '~/core/data/Endpoints';
import MappingTypeIndex from '~/mapping/mappingType/MappingTypeIndex';
import {MappingListQueryInput} from '~/graphqlTypes';

const MappingTypeListQuery = () => import('~/mapping/data/MappingTypeListQuery.graphql');

//
// TypeList
const typeList = LegacyGraphql<void>(MappingTypeListQuery);

//
// List
const list = async (payload: MappingListQueryInput, meta: Meta) => {
    try {
        const {queryFragment} = MappingTypeIndex[payload.type];

        // Fetch initial choices
        const {choiceFragment, choiceType} = MappingTypeIndex[payload.type];

        const query = `query MappingList($payload: MappingListQueryInput!) {
            mapping {
                mappingList(query: $payload) {
                    currentPage
                    currentSize
                    totalPages
                    totalItems
                    items {
                        id
                        type: __typename
                        createdAt
                        updatedAt
                        status
                        priority
                        note
                        ${queryFragment()}
                    }
                }
                ${
                    !choiceType
                        ? ''
                        : `choices(query: {type: "${choiceType}"}) {
                            type: __typename
                            ${choiceFragment?.()}
                        }`
                }
            }
        }`;

        const response = await LegacyGraphql(query)({payload}, meta);
        response.mapping.mappingList.id = meta.responseKey;
        return response;
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
    }
};

//
// Get

type GetVariables = {
    type: string;
    id: string;
};
const get = async (payload: GetVariables, meta: Meta) => {
    const {queryFragment} = MappingTypeIndex[payload.type];
    return LegacyGraphql(`query MappingItem($payload: MappingItemQueryInput!) {
        mapping {
            mappingItem(query: $payload) {
                type: __typename
                id
                createdAt
                updatedAt
                status
                priority
                note
                ${queryFragment()}
            }
        }
    }`)({payload}, meta);
};

type ChoicesVariables = {
    queryType: string;
    queryName?: string;
    fragment: string;
};
const getChoices = async (payload: ChoicesVariables | undefined, meta: Meta) => {
    const {queryName, queryType, fragment} = payload || {};

    return LegacyGraphql(`query MappingChoices($queryType: String!, $queryName: String) {
        mapping {
            choices(query: {name: $queryName, type: $queryType}) {
                type: __typename
                ${fragment}
            }
        }
    }`)({queryName, queryType}, meta);
};

//
// Update

type UpdateVariables = {
    type: string;
    data: any;
};
const update = async (variables: UpdateVariables, meta: Meta) => {
    const {type, data} = variables;

    const {payload, query} = MappingTypeIndex[type].update(data);
    if (!payload || !query) throw new Error(`${type} mapping has no mutation defined`);

    const update = await LegacyGraphql(query)({payload}, meta);
    const typeList = await LegacyGraphql(MappingTypeListQuery)({}, meta);

    update.mapping.mappingTypeList = typeList.mapping.mappingTypeList;
    return update;
};

export default {
    typeList,
    list,
    get,
    getChoices,
    update
};
