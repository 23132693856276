import {EntityApi as ApiFactory} from 'react-enty';
import EntitySchema from './EntitySchema';
import {LegacyGraphql, Meta} from '~/core/data/Endpoints';
import MapingApi from '~/mapping/data/MappingApi';
const ViewerQuery = LegacyGraphql(() => import('./ViewerQuery.graphql'));

const LegacyApi = ApiFactory(
    {
        viewer: async (teamId: string, meta: Meta) => {
            meta.responseKey = teamId;
            const response = await ViewerQuery({}, meta);
            response.viewer.currentTeamId = teamId;
            return response;
        },
        mapping: MapingApi
    },
    EntitySchema
);

export default LegacyApi;
