import React, {Suspense, useEffect} from 'react';
import {EntyProvider} from 'react-enty';
import LegacyApi from '~/core/data/LegacyApi';
import {
    DeadEnd404,
    ErrorBoundary,
    FeatureFlagsProvider,
    Loader,
    ModalProvider,
    useStageFavicon,
    Viewer
} from 'bigdatr-style';
import {Theme} from 'bigdatr-style';
import {ViewerProvider} from 'bigdatr-style';
import {BrowserRouter, Switch, useHistory, Redirect, useLocation} from 'react-router-dom';
import {Analytics, AnalyticsProvider} from 'bigdatr-style';
import {Segment} from 'bigdatr-style';
import {Sentry} from 'bigdatr-style';
import {useAuth} from 'bigdatr-style';
import {AuthProvider} from 'bigdatr-style';
import {ErrorBoundaryProvider} from 'bigdatr-style';
import {RoutesProvider, routes, useRoutes} from './Router';
import {useSettings} from './SettingsStore';
import useWebsocketSubscription from './useWebsocketSubscription';
import {Box} from 'bigdatr-style/layout';
import {FlexColumn} from 'bigdatr-style/layout';
import {Navigation} from 'bigdatr-style';
import {useLaunchDarklyClient} from 'bigdatr-style';
import {FilterClipboardProvider} from '../filters/FilterClipboardContext';
import useFeatureFlags from './useFeatureFlags';

const segment = new Segment(process.env.SEGMENT_WRITE_KEY || '');
const sentry = new Sentry({
    dsn: process.env.SENTRY_DSN || '',
    service: 'bigdatr-client-dataentry',
    environment: process.env.STAGE ?? '',
    release: process.env.RELEASE_NAME ?? ''
});
const analytics = new Analytics({segment, sentry});

export default function GlobalProviders() {
    const [settings] = useSettings();
    useStageFavicon();
    return (
        <ErrorBoundaryProvider value={{showAllErrorMessages: true}}>
            <BrowserRouter>
                <Theme
                    name={settings.theme}
                    fontStack={settings.fontStack}
                    spacing={settings.spacing}
                >
                    <FeatureFlagsProvider>
                        <AnalyticsProvider value={analytics}>
                            <ErrorBoundary>
                                <Suspense fallback={null}>
                                    <AuthProvider>
                                        <RoutesProvider>
                                            <FilterClipboardProvider>
                                                <EntyWithAuth />
                                            </FilterClipboardProvider>
                                        </RoutesProvider>
                                    </AuthProvider>
                                </Suspense>
                            </ErrorBoundary>
                        </AnalyticsProvider>
                    </FeatureFlagsProvider>
                </Theme>
            </BrowserRouter>
        </ErrorBoundaryProvider>
    );
}

function EntyWithAuth() {
    const authData = useAuth();

    return (
        <EntyProvider meta={authData}>
            <LegacyApi.Provider meta={authData}>
                <ViewerData />
            </LegacyApi.Provider>
        </EntyProvider>
    );
}

function ViewerData() {
    const {teamId} = useAuth();
    const viewerMessage = LegacyApi.viewer.useRequest();

    useEffect(() => {
        viewerMessage.request(teamId ?? 'NO_TEAM');
    }, []);

    if (viewerMessage.isError) throw viewerMessage.requestError;
    if (viewerMessage.isSuccess) return <WithViewer viewer={viewerMessage.response?.viewer} />;
    return <Loader />;
}

function WithViewer(props: {viewer: Viewer}) {
    const {viewer} = props;
    const ldClient = useLaunchDarklyClient();

    useEffect(() => {
        analytics.add('launchDarkly', ldClient);
        analytics.identify({
            id: viewer?.user.id,
            name: viewer?.user.name,
            firstname: viewer?.user.firstname,
            email: viewer?.user.username,
            teamName: viewer?.currentTeam.name,
            release: process.env.RELEASE_NAME
        });
    }, [viewer?.currentTeamId]);

    if (ldClient.isAnonymousContext()) return <Loader />;

    return (
        <ViewerProvider value={viewer}>
            <IndexView />
        </ViewerProvider>
    );
}

function IndexView() {
    const websocketSubscription = useWebsocketSubscription();
    const history = useHistory();
    const router = useRoutes();
    const location = useLocation();
    const {speedyMediaValue} = useFeatureFlags();

    useEffect(() => {
        if (websocketSubscription.isEmpty) websocketSubscription.request();

        // Only set up page tracking once
        analytics.page();
        return history.listen(() => analytics.page());
    }, []);

    const navigationItems = [
        {children: 'Creative Search', to: router.creativeTagger.to({})},
        {children: 'Upload', to: router.creativeUpload.to()},
        {children: 'Entities', to: router.entityList.to({type: 'brand'})},
        {children: 'Rules', to: router.ruleList.to()},
        {children: 'Alerts', to: router.alerts.to()},
        {children: 'Mapping', to: router.mapping.to({type: 'admonChannelMapping'})},
        ...(speedyMediaValue
            ? [{children: 'Changesets', to: router.changesets.to({id: undefined})}]
            : []),
        {
            children: speedyMediaValue ? 'Old releases' : 'Releases',
            to: router.releases.to({id: undefined})
        },
        {children: 'Settings', to: router.settings.to()},
        {children: 'Main app', href: process.env.BIGDATR_CLIENT_MAIN_HOST}
    ];

    return (
        <FlexColumn height="100vh">
            <ModalProvider>
                <Navigation small wrapper={false} items={navigationItems} />
                <Box flexGrow={1} overflowY="auto" borderTop="outline">
                    <ErrorBoundary resetKeys={[location.pathname]}>
                        <Switch>
                            {routes.alerts}
                            {routes.mapping}
                            {routes.creativeTagger}
                            {routes.creativeEdit}
                            {routes.creativeUpload}
                            {routes.creativeDone}
                            {routes.entityCreate}
                            {routes.entityList}
                            {routes.entityAttributes}
                            {routes.entityWordCloud}
                            {routes.entityHistory}
                            {routes.entityRules}
                            {routes.settings}
                            {routes.releases}
                            {routes.changesets}
                            {routes.ruleCreate}
                            {routes.ruleList}
                            {routes.ruleEdit}
                            {routes.ruleHistory}
                            {routes.ruleCreatives}
                            {routes.ruleApplyToHistoricalCreatives}
                            <Redirect exact from="/" to={router.creativeTagger.to({})} />
                            <DeadEnd404 />
                        </Switch>
                    </ErrorBoundary>
                </Box>
            </ModalProvider>
        </FlexColumn>
    );
}
