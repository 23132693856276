import React from 'react';

/**
Stolen the concept of hapi/boom, but applied to bigdatr clients.
Rendering errors in the client are often too adhoc and inconsitent, or create too much boiler-plate
setting up a bespoke dead end and adding it to the ErrorBoundary.

This is trying to create a middle-ground where the dev can throw a semantic error type, with a little
cusomisation, and error boundary can catch it and render a standardised DeadEnd

Note: this doesn't need to follow http error codes. It's trying to standardise the way we throw and
catch DeadEnds, and lower the boilerplate required.
*/
export default class Boom {
    _boom: true;
    name: string;
    message: React.ReactNode;

    constructor(input: {name: string; message: React.ReactNode}) {
        this.name = input.name;
        this.message = input.message;
    }

    /**
    Throw in a component to indicate something was not found
    @param noun - What type of data did we fail to find (campaign, workflow)
    @param message - A more detailed message to give the customer some context. Is a react node so we can put links in if need be.
    */
    static notFound(noun: string, message?: string) {
        return new Boom({
            name: `Sorry, we couldn't find this ${noun}`,
            message:
                message ||
                'We searched everywhere but we could not find what you’re looking for. Please check the URL address and give it another whirl.'
        });
    }

    /**
    Throw in a component to indicate we are in an empty state and there is nothing here yet
    */
    static empty(pluralNoun: string, message: string) {
        return new Boom({
            name: `No ${pluralNoun} here`,
            message
        });
    }

    //
    // Bespoke Errors
    //
    // These Errors are extremely specific and dripping layers and layers of premium business logic.
    // Don't bother trying to abstact them. Just embrace the pain.

    static cantBreakDown() {
        return new Boom({
            name: `Breakdown unavailable`,
            message:
                'There are some cases where the data requested is not available when viewing certain aggregations, breakdowns or filter types. This typically happens when the source data is not available to the granularity you have requested so cannot return any results.'
        });
    }

    static emptyList(pluralNoun: string) {
        return new Boom({
            name: `No ${pluralNoun} found`,
            message: `We couldn't find any ${pluralNoun}. Try a different set of filters.`
        });
    }

    static noData() {
        return new Boom({
            name: 'Data unavailable',
            message:
                'It looks like there is no activity for these search parameters, try a different set of filters.'
        });
    }
}
