import React from 'react';
import {MappingFormProps} from '../MappingUpdateStructure';
import pick from 'unmutable/pick';
import Mapping from '../data/Mapping';
import {Select} from 'bigdatr-style';
import InOutForm from '~/affordance/InOutForm';
import Term from '~/affordance/Term';
import {SchemaItem} from 'bigdatr-style/src/affordance/Table';

export const update = (data: Mapping) => ({
    payload: pick(['id', 'bigdatrMediaType', 'note', 'status'])(data),
    query: `
        mutation MappingUpdate($payload: SmiMediaTypeMappingMutation!) {
            mapping {
                mappingUpdate: smiMediaTypeMappingUpdate(mutation: $payload) {
                    id
                    type: __typename
                    createdAt
                    status
                    note
                    smiMediaType
                    smiMediaSubType
                    bigdatrMediaType
                }
            }
        }
    `
});

export const queryFragment = () => `
    ... on SmiMediaTypeMapping {
        smiMediaType
        smiMediaSubType
        bigdatrMediaType
    }
`;

export const tableSchema = (): Array<SchemaItem<Mapping>> => {
    return [
        {
            heading: 'SMI Media Type',
            key: 'smiMediaType',
            value: (mapping) => mapping.smiMediaType,
            render: (mapping) => <Term>{mapping.smiMediaType}</Term>
        },
        {
            heading: 'SMI Media Sub-type',
            key: 'smiMediaSubType',
            value: (mapping) => mapping.smiMediaSubType,
            render: (mapping) => <Term>{mapping.smiMediaSubType}</Term>
        },
        {
            heading: 'BigDatr Media Type',
            key: 'bigdatrMediaType',
            value: (mapping) => mapping.bigdatrMediaType
        }
    ];
};

export const Form: React.FC<MappingFormProps> = (props) => {
    const {mapping, onChange} = props;
    const {smiMediaSubType, smiMediaType, bigdatrMediaType} = mapping;

    return (
        <InOutForm inTable={{'Media Type': smiMediaType, 'Media Subtype': smiMediaSubType}}>
            <Select
                value={bigdatrMediaType}
                onChange={(bigdatrMediaType) => onChange({bigdatrMediaType})}
                right
                placeholder="select a media type"
                label={(x) => x}
                options={[
                    'Digital',
                    'Digital Display',
                    'Digital Search',
                    'Digital Social',
                    'Digital Video',
                    'Magazine',
                    'Newspaper',
                    'Out of Home',
                    'Radio',
                    'Regional Radio',
                    'Regional TV',
                    'STV',
                    'Television'
                ]}
            />
        </InOutForm>
    );
};
