import {useEffect, useRef} from 'react';

/**
useHotkey

Hotkeys are hard. This is intented to provide an ergenomic way to apply add keyboard shortcuts
to components so it's really natural to render a component and it's shortcuts magically work,
but only while it's on screen.

@todo Probably worth some kind of caching so we're not making a million event listeners
*/
export default function useHotkey<T extends HTMLElement>(
    bindings: {[key: string]: () => void},
    deps: Array<any> = []
) {
    const ref = useRef<T | null>(null);
    function downHandler(e: KeyboardEvent) {
        const target = e.target as HTMLElement | null;
        const modifiers = {
            alt: e.altKey,
            meta: e.metaKey
        };

        const isOnScreen = ref.current?.offsetParent !== null;

        // There are basically no reasons to use simple hotkeys while typing
        // If you are building a texteditor stop using this hook.
        const isSafeNode = !['INPUT', 'TEXTAREA'].includes(target?.nodeName ?? '');

        if (isOnScreen && isSafeNode) {
            Object.entries(bindings).forEach(([hotkey, cb]) => {
                const isKeyPressed = hotkey.split('+').every((k) => {
                    return modifiers[k] || CODES[k] === e.code || e.key === k;
                });
                if (isKeyPressed) cb();
            });
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', downHandler);
        return () => {
            document.removeEventListener('keydown', downHandler);
        };
    }, deps);

    return ref;
}

const CODES = {
    0: 'Digit0',
    1: 'Digit1',
    2: 'Digit2',
    3: 'Digit3',
    4: 'Digit4',
    5: 'Digit5',
    6: 'Digit6',
    7: 'Digit7',
    8: 'Digit8',
    9: 'Digit9',
    space: 'Space',
    left: 'ArrowLeft',
    escape: 'Escape',
    right: 'ArrowRight'
};
