import {ComponentType, ElementRef, CSSProperties} from 'react';
import styled from 'styled-components';
import {ResponsiveValue, system} from 'styled-system';
import {
    space,
    SpaceProps,
    color,
    ColorProps,
    layout,
    LayoutProps,
    flexbox,
    FlexboxProps,
    grid,
    GridProps,
    position,
    PositionProps,
    background,
    BackgroundProps,
    border,
    BorderProps,
    compose,
    textStyle,
    TextStyleProps,
    typography,
    TypographyProps,
    shadow,
    ShadowProps
} from 'styled-system';

const aspectRatio = system({
    aspectRatio: {
        property: 'aspectRatio',
        scale: 'breakpoints'
    }
});
type AspectRatioProps = {aspectRatio?: ResponsiveValue<string>};

const cursor = system({
    cursor: {property: 'cursor'}
});
type CursorProps = {cursor?: CSSProperties['cursor']};

const flexGap = system({
    gap: {
        property: 'gap',
        scale: 'space'
    }
});
type FlexGapProps = {gap?: ResponsiveValue<number | string>};

const float = system({float: true});
type FloatProps = {float?: 'left' | 'right'};

const inset = system({
    inset: {
        property: 'inset',
        scale: 'space'
    }
});
type InsetProps = {inset?: ResponsiveValue<string | number>};

const outline = system({
    outline: {
        property: 'outline',
        scale: 'borders'
    }
});
type OutlineProps = {outline?: string};

const placeContent = system({
    placeContent: {
        property: 'placeContent'
    }
});
type PlaceContentProps = {placeContent?: ResponsiveValue<CSSProperties['placeContent']>};

const placeItems = system({
    placeItems: {
        property: 'placeItems'
    }
});
type PlaceItemsProps = {placeItems?: ResponsiveValue<CSSProperties['placeItems']>};

const transform = system({
    transform: {
        property: 'transform',
        scale: 'breakpoints'
    }
});
type TransformProps = {transform?: ResponsiveValue<string>};

const visibility = system({
    visibility: {
        property: 'visibility'
    }
});
type VisibilityProps = {visibility?: ResponsiveValue<CSSProperties['visibility']>};

export const styledProps = compose(
    aspectRatio,
    background,
    border,
    color,
    cursor,
    flexbox,
    flexGap,
    float,
    grid,
    inset,
    layout,
    outline,
    placeContent,
    placeItems,
    position,
    shadow,
    space,
    textStyle,
    transform,
    typography,
    visibility
);

export interface StyledProps
    extends AspectRatioProps,
        BackgroundProps,
        BorderProps,
        ColorProps,
        CursorProps,
        FlexboxProps,
        FlexGapProps,
        FloatProps,
        GridProps,
        InsetProps,
        LayoutProps,
        OutlineProps,
        Omit<PositionProps, 'zIndex'>,
        PlaceContentProps,
        PlaceItemsProps,
        ShadowProps,
        SpaceProps,
        TextStyleProps,
        TransformProps,
        TypographyProps,
        VisibilityProps {
    children?: React.ReactNode;
    className?: string;
    onClick?: Function;
    style?: React.CSSProperties;
    ref?: ElementRef<any>;
    zIndex?: number | string; // Can't get the proper type here. For some reason the Default theme extension is undefined
}

export {default as AspectRatio} from './src/layout/AspectRatio';

const config = {
    shouldForwardProp: (prop, defaultValidatorFn) =>
        !['width', 'height', 'display', 'color'].includes(prop) && defaultValidatorFn(prop)
};

export const Box = styled.div.withConfig(config)<StyledProps>({display: 'block'}, styledProps);
Box.displayName = 'Box';

export const Flex = styled.div.withConfig(config)<StyledProps>({display: 'flex'}, styledProps);
Flex.displayName = 'Flex';

export const FlexColumn = styled.div.withConfig(config)<StyledProps>(
    {display: 'flex', flexDirection: 'column'},
    styledProps
);
FlexColumn.displayName = 'FlexColumn';
export const FlexCenter = styled.div.withConfig(config)<StyledProps>(
    {display: 'flex', justifyContent: 'center', alignItems: 'center'},
    styledProps
);
FlexColumn.displayName = 'FlexColumn';

export const Grid = styled.div.withConfig(config)<StyledProps>({display: 'grid'}, styledProps);
Grid.displayName = 'Grid';

export const Hidden = styled.div.withConfig(config)<StyledProps>({display: 'none'}, styledProps);
Hidden.displayName = 'Hidden';

export const Fixed = styled.div.withConfig(config)<StyledProps>({position: 'fixed'}, styledProps);
Fixed.displayName = 'Fixed';

export const Absolute = styled.div.withConfig(config)<StyledProps>(
    {position: 'absolute'},
    styledProps
);
Absolute.displayName = 'Absolute';

export const Relative = styled.div.withConfig(config)<StyledProps>(
    {position: 'relative'},
    styledProps
);
Relative.displayName = 'Relative';

// Tables
type CellProps = {colSpan?: number};
export const Table = styled.table.withConfig(config)<StyledProps>({}, styledProps);
Table.displayName = 'Table';

export const TableBody = styled.tbody.withConfig(config)<StyledProps>({}, styledProps);
TableBody.displayName = 'TableBody';

export const TableRow = styled.tr.withConfig(config)<StyledProps>({}, styledProps);
TableRow.displayName = 'TableRow';

export const TableHead = styled.thead.withConfig(config)<StyledProps>({}, styledProps);
TableHead.displayName = 'TableHead';

export const TableHeadCell: ComponentType<StyledProps & CellProps> = styled.th.withConfig(
    config
)<StyledProps>({textAlign: 'left'}, styledProps);
TableHeadCell.displayName = 'TableHeadCell';

export const TableCell: ComponentType<StyledProps & CellProps & {style?: React.CSSProperties}> =
    styled.td.withConfig(config)<StyledProps>({}, styledProps);
TableCell.displayName = 'TableCell';

export const Wrapper: ComponentType<StyledProps & {modifier?: string}> = styled.div.withConfig(
    config
)<StyledProps>(
    {
        margin: 'auto',
        maxWidth: '80rem',
        position: 'relative'
    },
    styledProps
);
Wrapper.displayName = 'Wrapper';

export const Ol: ComponentType<StyledProps> = styled.ol.withConfig(config)(
    {
        paddingLeft: '1.5rem',
        listStyleType: 'number'
    },
    styledProps
);
Ol.displayName = 'Ol';

export const Ul: ComponentType<StyledProps> = styled.ul.withConfig(config)(
    {
        paddingLeft: '1.5rem',
        listStyleType: 'disc'
    },
    styledProps
);
Ul.displayName = 'Ul';

export const Li: ComponentType<StyledProps> = styled.li.withConfig(config)({}, styledProps);
Li.displayName = 'Li';

type SvgProps = StyledProps & {viewBox: string; stroke?: string; strokeWidth?: string};
export const Svg: ComponentType<SvgProps> = styled.svg.withConfig(config)<SvgProps>(
    {},
    styledProps
);
Svg.displayName = 'Svg';
