import React from 'react';
import {Svg} from '../../layout';
import {LightTheme as c} from 'bigdatr-theme';

const stampyInBath = () => (
    <Svg height={{_: '12rem', sm: '20rem'}} viewBox="0 0 330 293">
        <path
            fill="#DBE7E8"
            d="M276.6,196.8c-12.9,2.8-26.3,4.5-37.6,4.5H26c-24.7,0-30.5-8.2-12.7-18.2l0,0c17.8-10,52.6-18.2,77.3-18.2 c0,0,48.8,0,99.7,0c55.4,0,113.3,0,113.3,0c24.8,0,30.5,8.2,12.7,18.2l0,0C306.7,188.6,292,193.5,276.6,196.8z"
        />
        <g>
            <path
                fill={c.blue}
                d="M26,202l213-1c11.3,0,24.7-1.5,37.6-4.3c15.4-3.3,30.1-8.1,39.7-13.5l0,0c2.6-1.5,4.7-2.7,6.3-4.1 c-3.6-0.8-8.3-1.1-14-1.1c0,0-57.9,0-113.3,0c-50.9,0-99.7,0-99.7,0c-24.8,0-59.6,8-77.6,18v-0.1c-2,1.4-4.3,2.7-5.9,4 C15.7,200.7,20.4,202,26,202z"
            />
        </g>
        <path
            fill="#B7D3D4"
            d="M326.7,179.8c-0.1,3-3.5,6.5-10.3,10.4l0,0c-9.7,5.4-24.4,10.2-39.7,13.5c-12.9,2.8-26.3,4.3-37.6,4.3H26 c-12.7,0-20.4-2-22.5-5.5L3,202.7c0.1,5.1,0.5,9.9,1,14.3c3.8,32,31.2,56,63.4,56H217c13.7,0,60.8-0.8,86-21.2c24-19.5,24-72,24-72 L326.7,179.8z"
        />
        <path
            fill={c.brand}
            d="M147,191v-23h13v23h37v-5h13v5h27v-39.2c0-12.7-9.5-23.2-21.7-25.1c-0.3-14.1-11.9-25.1-26.1-25.1 c-14.1,0-25.5,11.4-26.1,25.4H92.6c-9.3,0-16.6,6.9-16.6,16.2V191H147z M211.4,146.9c0.4-1.4,1.5-2.6,2.9-2.9 c2.9-0.6,5.4,1.8,4.8,4.8c-0.3,1.4-1.4,2.6-2.9,2.9C213.2,152.3,210.8,149.8,211.4,146.9z"
        />
        <rect x="253" y="32" fill="#C6C6C6" width="6" height="133" />
        <ellipse fill="#C6C6C6" cx="197.5" cy="36" rx="17.5" ry="3" />
        <path
            fill="#C6C6C6"
            d="M200,37v-9.4c0-9.1,7.4-16.6,16.6-16.6h19.9c9.1,0,16.6,7.4,16.6,16.6V37h6v-8.9C259,15.4,248.6,5,235.9,5 h-18.7C204.4,5,194,15.4,194,28.1V37H200z"
        />
        <rect x="253" y="159" fill="#AFAFAF" width="6" height="6" />
        <path
            fill="#ACC6C6"
            d="M283.3,259.4c-1.9-1.5-2.7,0.3-5.3,1.6c-2.3,1.1-6.3,1.1-6,3.6c0.4,3.4,3.8,3.6,8.3,9.5 c2.8,3.7,2.2,4.6,4.3,6.5c4.7,4,12.3,3.9,13,2.2c0.6-1.6-5.4-2.9-9.1-9.5C285,267,287.3,262.6,283.3,259.4z"
        />
        <path
            fill="#ACC6C6"
            d="M33.4,261.4c1.9-1.5,2.7,0.3,5.3,1.6c2.3,1.1,6.3,1.1,6,3.6c-0.4,3.4-3.8,3.6-8.3,9.5 c-2.8,3.7-2.2,4.6-4.3,6.5c-4.7,4-12.3,3.9-13,2.2c-0.6-1.6,5.4-2.9,9.1-9.5C31.7,269,29.3,264.6,33.4,261.4z"
        />
        <g>
            <path
                fill="#ACC6C6"
                d="M298.8,253.4c-24.6,12.1-70.6,12.6-84,12.6H68.7c-21.6,0-41-6.7-52.2-17c11.8,14.7,29.9,24,49.9,24H216 c13.7,0,60.8-0.8,86-21.2c0.2-0.2,0.4-0.3,0.6-0.5C301.4,252,300.1,252.8,298.8,253.4z"
            />
        </g>
    </Svg>
);

export default stampyInBath;
