import {useState, useCallback, useEffect} from 'react';

type Go = <K>(nextState: K, context?: any) => void;

export default function useStateMachine<
    C extends {
        [key: string]: (go: Go, context: any) => Promise<any>;
    }
>(
    initialState: keyof C,
    stateChart: C
): {
    state: keyof C;
    go: Go;
    context: any;
} {
    const [{key, context}, setState] = useState<any>({key: initialState, context: null});

    const go = useCallback(
        (key, context) => {
            if (stateChart[key]) return setState({key, context});
            setState({key: 'error', context: Error('Invalid State')});
        },
        [key, context]
    );

    useEffect(() => {
        let mounted = true;
        (async () => {
            try {
                await stateChart[key](go, context);
            } catch (e) {
                if (mounted) setState({key: 'error', context: e});
            }
        })();
        return () => {
            mounted = false;
        };
    }, [key]);

    return {state: key, go, context} as const;
}
