import React from 'react';
import DeadEnd from '../affordance/DeadEnd';
import StampyBath from '../scenes/stampy_in_bath';
import {Link} from '../index';

type Props = {
    action?: null | (() => React.ReactNode); // Allow no action
};

export default function DeadEnd404(props: Props) {
    const {action = () => <Link to="/">{'Back to Bigdatr'}</Link>} = props;

    return (
        <DeadEnd title="Page not found" stampy={StampyBath} action={action}>
            We searched everywhere but we could not find what you’re looking for. Please check the
            URL address and give it another whirl.
        </DeadEnd>
    );
}
