import styled from 'styled-components';

export default styled.div.attrs((props: {size?: string}) => ({
    size: props.size || '1rem',
    'data-testid': 'Spinner'
}))`
    width: ${(props) => props.size || '1rem'};
    height: ${(props) => props.size || '1rem'};
    border: 3px solid currentColor;
    border-right-color: transparent;
    border-radius: 100%;
    animation: spinner-rotate 1s infinite linear;

    @keyframes spinner-rotate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;
