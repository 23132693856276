import {LightTheme as colors} from 'bigdatr-theme';

const colorMap = {
    Brand: {color: colors.blue},
    Retail: {color: colors.red},
    Sponsorship: {color: colors.cyan},
    Competition: {color: colors.yellow},
    Uncategorised: {color: colors.muted}
};

export type AdTypeInput = {name: string; id?: string};

export default class AdType {
    private data: AdTypeInput;
    type = 'adType' as const;

    constructor(input: AdTypeInput) {
        this.data = input;
    }

    get id() {
        return this.data.id || this.data.name;
    }

    get label() {
        return this.data.name;
    }

    get color() {
        return colorMap[this.label]?.color ?? colors.muted;
    }
}
