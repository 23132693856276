import UnmutableCompatible from '../util/UnmutableCompatible';

export type CreativeMedium = 'image' | 'video' | 'audio';

export default class Media<T extends Record<string, any>> extends UnmutableCompatible<T> {
    id: string;
    creative: string;
    private mediaUrl = process.env.MEDIA_URL ?? '';
    constructor(data: {id: string; creative: string}) {
        super();
        this.id = data.id;
        this.creative = data.creative;
    }

    extension(): string | undefined {
        return this.creative.substr(this.creative.lastIndexOf('.') + 1);
    }

    thumbnailUrl(
        variant: 'w512' | 'w512t1' | 'w512c16_9' | 'w1680' | 'w512c16_9t1',
        type: 'jpg' | 'webp'
    ) {
        const file = this.safeThumbnailFile(type);
        return `${this.mediaUrl}creatives/${this.extension()}/${variant}/${file}`;
    }

    safeThumbnailFile(extension: 'jpg' | 'webp'): string {
        return this.creative.replace(`.${this.extension()}`, `.${extension}`);
    }

    get creativeMedium(): CreativeMedium {
        // Add any new video or audio filetypes here
        // NOTE: there are unsupported formats at this time which are defaulting to img
        const videoRgx = /\.(mp4|webm)$/i;
        const audioRgx = /\.(mp3|wav)$/i;

        if (this.creative.match(videoRgx)) {
            return 'video';
        } else if (this.creative.match(audioRgx)) {
            return 'audio';
        }
        return 'image';
    }

    get thumbnail(): string {
        return this.thumbnailUrl('w512', 'jpg');
    }

    get thumbnailNoTile(): string {
        return this.thumbnailUrl('w512t1', 'jpg');
    }

    get thumbnailLarge(): string {
        return this.thumbnailUrl('w1680', 'jpg');
    }

    get filepath(): string {
        return `${this.mediaUrl}creatives/${this.extension()}/original/${this.creative}`;
    }

    get downloadPath(): string {
        return `${this.filepath}?download=true`;
    }
}
