import {ApiError} from '../../error/errors';
import jwtDecode from 'jwt-decode';
import {TokenViewer} from './AuthProvider';

const AUTH_URL = process.env.BIGDATR_AUTH_URL as string;
const client_id = process.env.BIGDATR_AUTH_CLIENT_ID as string;
const redirect_uri = process.env.BIGDATR_AUTH_REDIRECT_SIGN_IN as string;

//
// Redirect to oauth url with correct query params applied
export async function OAuthNavigate(
    pathname: 'login' | 'logout' | 'forgotPassword',
    state: Record<string, any>
) {
    const search = new URLSearchParams({
        redirect_uri,
        client_id,
        response_type: 'code',
        state: JSON.stringify(state)
    }).toString();
    window.location.href = `${AUTH_URL}/${pathname}?${search}`;
}

//
// Make a post request to get new access and refresh tokens
// from either a code grant or a refresh token.
export async function OAuthPost(
    type: 'accessToken' | 'refreshToken',
    tokenOrCode: string | null
): Promise<{accessToken: string; refreshToken: string}> {
    if (!tokenOrCode) throw new Error('refreshToken or code is missing');
    const response = await fetch(`${AUTH_URL}/oauth2/token`, {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body: new URLSearchParams(
            type === 'accessToken'
                ? {grant_type: 'authorization_code', client_id, redirect_uri, code: tokenOrCode}
                : {grant_type: 'refresh_token', client_id, refresh_token: tokenOrCode}
        ).toString()
    });

    const body = await response.json();
    if (!response.ok) {
        if (body && body.error) throw new ApiError(body.error);
        throw new Error(JSON.stringify(body));
    }
    if (!body.access_token || !body.refresh_token) throw new Error('Body has no token');
    return {
        accessToken: body.access_token,
        refreshToken: body.refresh_token
    };
}

export function EagerNow() {
    return Math.floor(Date.now() / 1000);
}

export function ParseToken(accessToken: string | null): {
    availableTeamIds: string[];
    tokenExpiry: number;
    viewerData: TokenViewer;
} {
    if (!accessToken) throw new Error('cannot parse empty token'); // @todo
    const {exp, iat, viewerData} = jwtDecode(accessToken);
    const ttl = exp - iat;
    return {
        availableTeamIds: viewerData?.teamIds || [],
        tokenExpiry: EagerNow() + ttl * 0.75,
        viewerData: viewerData as TokenViewer
    };
}
