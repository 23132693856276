import styled from 'styled-components';
import {bool} from '../core/themes/themeGetters';

type Props = {
    hasValue?: boolean;
    disabled?: boolean;
    borderRadiusLeft?: boolean;
    dashedBorder?: boolean;
    isError?: boolean;
    transparent?: boolean;
    small?: boolean;
    hideCaret?: boolean;
};
export default styled.button<Props>`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border: 2px solid;
    border-color: ${(p) => p.theme.colors.outline};
    background-color: ${(props) => props.theme.colors.background};
    border-radius: ${({borderRadiusLeft = true}) => (borderRadiusLeft ? '2rem' : '0 2rem 2rem 0')};
    padding: 0.125rem 1rem;
    height: 2rem;
    color: inherit;
    white-space: nowrap;
    cursor: pointer;

    ${bool('small')(() => {
        return `
            font-size: .85rem;
            padding-left: .75rem;
            padding-right: .75rem;
            height: 1.85rem;
        `;
    })}

    ${bool('disabled')((p) => {
        return `
            color: ${p.theme.colors.muted};
            border-color: ${p.theme.colors.outlineDisabled};
            background-color: ${p.theme.colors.background1};
            cursor: not-allowed;
        `;
    })}

    ${bool('transparent')((p) => {
        return `
            padding-left: .25rem;
            padding-right: .25rem;
            border-color: transparent;
            background-color: transparent;
            width: auto;
        `;
    })}

    

    ${bool<Props>('hasValue')((p) => {
        return `
            background-color: ${p.theme.colors.active};
            border-color: ${p.theme.colors.active};
        `;
    })}

    ${bool('dashedBorder')((p) => {
        return `
            border-style: dashed;
            border-color: ${p.theme.colors.background};
        `;
    })}

    ${bool('isError')(
        (p) => `
            border-color: ${p.theme.colors.red};
        `
    )}

    


    &:hover,
    &:focus {
        outline: none;
    }

    &:hover,
    &:focus-visible {
        opacity: 0.7;
        outline: none;
    }

    &::after {
        display: inline-block;
        content: '▾';
        margin-left: 0.25rem;
    }

    ${bool('hideCaret')(() => {
        return '&::after {content: none;}';
    })}
`;
