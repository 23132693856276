import React, {useState, useRef} from 'react';
import {Box} from '../../layout';
import styled from 'styled-components';
import {Label, Portal, useStateWithDeps} from '../index';
import {ButtonPill} from '../index';
import MenuItem from './MenuItem';
import Text from './Text';
import {
    useFloating,
    useFocus,
    useInteractions,
    useClick,
    useRole,
    useDismiss,
    useListNavigation,
    autoUpdate,
    offset,
    shift,
    flip
} from '@floating-ui/react';

type Props<A> = {
    value: A;
    options: Array<A>;
    onChange: (next: A) => void;

    /** Sets the option ID */
    id?: (item: A) => string;
    label?: (item: A) => React.ReactNode;
    optionDisabled?: (item: A) => boolean;

    //
    // Control props
    placeholder?: string;
    disabled?: boolean;
    isError?: boolean;
    testId?: string;
    transparent?: boolean;
    formLabel?: React.ReactNode;
    controlLabel?: (item: A) => React.ReactNode;
    hideCaret?: boolean;
    unknownOptionLabel?: (item: A) => React.ReactNode;

    //
    // Floater props
    /** Align the right edge of the floater to the right edge of control. Defaults to `false` */
    right?: boolean;
    /** Sets the width of the floater. Defaults to `max-content` */
    width?: string;
    /** Sets the max-height of the floater */
    maxHeight?: string;
    onIsOpenToggle?: (isOpen?: boolean) => void;
};

export default function Select<A>(props: Props<A>) {
    const {
        options,
        value,
        width = 'max-content',
        maxHeight = 'clamp(10rem, 25rem, 80vh)',
        right,
        id = (x) => x,
        label = (x) => x,
        placeholder = '',
        formLabel,
        disabled,
        isError
    } = props;

    const selectedItemIndex = options.findIndex((ii) => id(value) === id(ii));
    const selectedItem = selectedItemIndex > -1 ? options[selectedItemIndex] : null;

    const [isOpen, setIsOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [statefulSelectedIndex, setStatefulSelectedIndex] = useStateWithDeps<number | null>(
        selectedItemIndex > -1 ? selectedItemIndex : null,
        [JSON.stringify(value)]
    );
    const listRef = useRef<HTMLDivElement[]>([]);

    const {refs, floatingStyles, context, middlewareData} = useFloating({
        placement: right ? 'bottom-end' : 'bottom-start',
        open: isOpen,
        onOpenChange: (next) => {
            setIsOpen(next);
            props.onIsOpenToggle?.(next);
        },
        whileElementsMounted: autoUpdate,
        // order of middleware functions is important
        middleware: [
            offset(8),
            // keep floater in view if a horizontal scroll would make it offscreen
            shift(),
            // change floater placement to top of reference node if it would be offscreen when scrolling
            flip()
        ]
    });

    const focus = useFocus(context);
    const click = useClick(context);
    const role = useRole(context, {role: 'select'});
    const dismiss = useDismiss(context);

    const listNavigation = useListNavigation(context, {
        listRef,
        activeIndex,
        selectedIndex: statefulSelectedIndex,
        onNavigate: setActiveIndex,
        loop: true,
        enabled: !disabled
    });

    const {getFloatingProps, getReferenceProps, getItemProps} = useInteractions([
        focus,
        click,
        role,
        dismiss,
        listNavigation
    ]);

    const controlLabel = () => {
        if (selectedItem != null) {
            if (props.controlLabel) return props.controlLabel(selectedItem);
            return label(selectedItem);
        }
        if (props.unknownOptionLabel) return props.unknownOptionLabel(props.value);
        return <Text textStyle="muted" whiteSpace="nowrap" children={placeholder} />;
    };

    return (
        <>
            <Box data-testid={props.testId} ref={refs.setReference} {...getReferenceProps()}>
                {formLabel && <Label>{formLabel}</Label>}
                <ButtonPill
                    disabled={disabled}
                    isError={isError}
                    transparent={props.transparent}
                    hideCaret={props.hideCaret}
                >
                    <>{controlLabel()}</>
                </ButtonPill>
            </Box>
            {isOpen && (
                <Portal>
                    <Menu
                        ref={refs.setFloating}
                        style={{
                            ...floatingStyles,
                            visibility: middlewareData.hide?.referenceHidden ? 'hidden' : 'visible'
                        }}
                        {...getFloatingProps()}
                        width={width}
                        maxHeight={maxHeight}
                    >
                        {options.map((item, index) => (
                            <MenuItem
                                disabled={props.optionDisabled ? props.optionDisabled(item) : false}
                                active={activeIndex === index}
                                key={`${item}${index}`}
                                tabIndex={activeIndex === index ? 0 : -1}
                                ref={(node: HTMLDivElement) => {
                                    listRef.current[index] = node;
                                }}
                                children={label(item)}
                                {...getItemProps({
                                    onClick: () => {
                                        props.onChange(item);
                                        setStatefulSelectedIndex(index);
                                        setIsOpen(false);
                                    }
                                })}
                            />
                        ))}
                    </Menu>
                </Portal>
            )}
        </>
    );
}

const Menu = styled(Box)`
    background-color: ${(p) => p.theme.colors.background};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    border-radius: 0.5rem;
    border: 2px solid ${(_) => _.theme.colors.brand};
    z-index: ${(p) => p.theme.zIndices.Dropdown};
    overflow: ${(p) => (p.maxHeight ? 'auto' : 'hidden')};
`;
