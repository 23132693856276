import {proxy, useSnapshot} from 'valtio';

// @intent - we want to keep the changeset list up to date when you or someone else creates a new
// changeset. we use this piece of state to mark when was it last updated, and then listen for
// websocket create messages in a useEffect, which refetches the list

export const changesetListLastUpdatedProxy = proxy({
    listLastUpdated: new Date()
});

export const changesetListLastUpdatedSnapshot = () => useSnapshot(changesetListLastUpdatedProxy);
