import {ApiError} from 'bigdatr-style';
import type {Api as ApiType} from '../../../../bigdatr-api-dataentry/dist/src/handlers/api';
import type {WsMessage} from '../../../../bigdatr-api-dataentry/dist/src/services/ws';
import {CreateApiTypes, createRequester} from 'bigdatr-rpc/client';

type QueryModule = string | (() => Promise<{default: string}>);
type Config = {
    strict?: boolean;
};
export type Meta = {
    responseKey: string;
    authorizedFetch: <T>(
        url: string,
        payload: any,
        method?: 'POST' | 'GET' | 'DELETE'
    ) => Promise<T>;
    authorizedWebsocket: (url: string) => AsyncGenerator<unknown, void, void>;
    getState: () => any;
};

export function LegacyGraphql<V = unknown>(getQueryModule: QueryModule, config: Config = {}) {
    return async (variables: V, meta: Meta) => {
        const {strict = false} = config;
        const responseKey = meta.responseKey;
        let query: string;
        const url = `${process.env.BIGDATR_DATAENTRY_API_LEGACY_ENDPOINT || ''}`;
        if (typeof getQueryModule === 'function') {
            query = (await getQueryModule()).default;
        } else {
            query = getQueryModule;
        }
        const start = new Date();

        return meta.authorizedFetch(url, {query, variables}).then(({errors, data}) => {
            const end = new Date();
            const responseMeta = {
                id: responseKey,
                start,
                end,
                url,
                query,
                variables,
                errors
            };

            if (strict && (errors || []).length > 0) throw new ApiError(errors[0]);

            return {
                responseMeta,
                ...data
            };
        });
    };
}

export type WebsocketMessage = WsMessage;
export function Websocket(meta: Meta): AsyncGenerator<unknown, void, void> {
    return meta.authorizedWebsocket(process.env.BIGDATR_DATAENTRY_WEBSOCKET_ENDPOINT || '');
}

export type DataEntryRpc = CreateApiTypes<ApiType>;
export const RpcRequest = createRequester<ApiType>(async (payload, meta: Meta) => {
    return meta.authorizedFetch(
        new URL('rpc', process.env.BIGDATR_DATAENTRY_API_ENDPOINT || '').toString(),
        payload
    );
});
