import {EntitySchema, ArraySchema, ObjectSchema, Schema} from 'react-enty';
import get from 'unmutable/get';

export const entityId = (key: string) => (data: Record<string, any>) => get(key)(data) || 'NONE';

export class RecordSchema extends ObjectSchema {
    create: Function;
    merge: Function;
    constructor(Record, shape, options = {}) {
        super(shape, options);
        this.create = (item) => Record.fromUnknown(item);
        this.merge = (previous, next) => previous.merge(next);
    }
}

export class ClassSchema<A extends {new (a: any): any}> extends ObjectSchema {
    model: A;
    constructor(model: A, shape: Record<string, Schema>, options = {}) {
        super(shape, options);
        this.model = model;
    }
    denormalize(denormalizeState, path) {
        const data = super.denormalize(denormalizeState, path);
        return data ? new this.model(data) : data;
    }
}

export const createListEntitySchemas = (name: string, shape: Schema, parentId?: Function) => {
    parentId = parentId || (() => name);
    const baseList = <A extends {}>(options: A) =>
        new EntitySchema(name, {
            id: parentId,
            shape: new ArraySchema(shape, options)
        });

    return [
        baseList({merge: (_: any[], bb: any[]) => bb}), // set
        baseList({merge: (aa: any[], bb: any[]) => aa.concat(bb)}), // add
        baseList({merge: (aa: any[], bb: any[]) => aa.filter((id) => !bb.includes(id))}) // remove
    ];
};

/**
A schema that handles the concatentation of mutliple pages for you.
Configure it with a function to get the pageNumber from the response and a function to flatten
a list of data into a single data item. It will then store the data in entity state as indivual pages,
but return them as a single long item. Not suitable if you want your component to have one page at a time,
but really good for virtualized infinite scroll.
*/
export class InfinitePaginationSchema<T> extends EntitySchema {
    pageNumber: (x: T) => number;
    rootId: (x: T) => string;
    flatten: (list: T[], mostRecent: T) => T;
    name: string;
    cursorBased: boolean;
    constructor(
        name: string,
        options: {
            id: (x: T) => string;
            shape: any;
            pageNumber: (x: T) => number;
            flatten: (list: T[], mostRecent: T) => T;
            cursorBased?: boolean;
        }
    ) {
        super(name, {
            id: (data) => `${options.id(data)}-${options.pageNumber(data)}`,
            shape: options.shape
        });
        this.pageNumber = options.pageNumber;
        this.flatten = options.flatten;
        this.name = name;
        this.rootId = (data) => `${options.id(data)}-list`;
        this.cursorBased = options.cursorBased ?? false;
    }
    normalize(input, entities) {
        // Normalize this item
        const nextState = super.normalize(input, entities);
        // Calculate the page number and id of every list;
        const pageNumber = this.pageNumber(input);
        const id = this.rootId(input);

        // Store this result under the rootId
        entities[this.name][id] ||= this.cursorBased ? {} : [];
        entities[this.name][id][pageNumber] = nextState.result;
        nextState.result = id;
        return nextState;
    }
    denormalize({entities, result}, path) {
        if (result == null) return result;
        const ids = entities[this.name][result] || (this.cursorBased ? {} : []);
        const idList = Array.isArray(ids) ? ids : Object.values(ids);
        const children: T[] = idList
            .map<T>((id) => super.denormalize({entities, result: id}, path) as T)
            .filter((ii) => ii);

        return this.flatten(children, children[children.length - 1]);
    }
}
