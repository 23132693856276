import React from 'react';
import Loader from './Loader';
import Paper from './Paper';
import Text from './Text';
import {Box} from '../../layout';

export default function ModalPending(props: {title: string; subtitle?: string}) {
    return (
        <Paper round p={4}>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Loader noMargin />
                <Text textStyle="heading3" mt={4}>
                    {props.title}
                </Text>
                <Text mt={1} textAlign="center">
                    {props.subtitle || 'This should only take a minute'}
                </Text>
            </Box>
        </Paper>
    );
}
