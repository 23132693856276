import styled from 'styled-components';
import Clickable, {ClickableProps} from './Clickable';
import React from 'react';
import {Absolute} from '../../layout';
import Spinner from './Spinner';

type Props = ClickableProps & {
    active?: boolean;
    small?: boolean;
    large?: boolean;
    tight?: boolean;
    rounded?: boolean;
    loading?: boolean;
};

export default styled(
    React.forwardRef((props: Props, ref) => {
        if (props.loading)
            return (
                <Clickable {...props} disabled ref={ref}>
                    <div style={{opacity: 0}}>{props.children}</div>
                    <Absolute
                        inset="1rem"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Spinner />
                    </Absolute>
                </Clickable>
            );
        return <Clickable {...props} ref={ref} />;
    })
)((props) => {
    const {rounded, active, small, large, tight, loading} = props;
    const {colors} = props.theme;

    const activeStyle = `
        outline: none;
        color: ${colors.brand};
    `;

    return `
        position: relative;
        display: block;
        width: 100%;
        padding: 0.5rem 1rem;
        font-size: 1em;
        line-height: 1.5;
        ${rounded ? 'border-radius: .25rem;' : ''}

        ${tight ? `padding: 0;` : ''}

        ${large ? `padding: 1rem;` : ''}

        ${small ? `padding: .25rem 1rem;` : ''}

        ${loading ? `cursor: not-allowed;` : ''}

        &:hover {
            background-color: ${colors.backgroundHover};
        }

        ${active ? activeStyle : ''}
        &.active,
        &:active,
        &:focus {
            ${activeStyle}
        }
    `;
});
