import {Websocket, WebsocketMessage} from '~/core/data/Endpoints';
import {ObjectSchema, createRequestHook} from 'react-enty';
import superjson from 'superjson';
import {
    creativeItemSchema,
    releaseItemSchema,
    buildItemSchema,
    changesetItemSchema,
    changesetSelectionItem,
    changesetSelectionList
} from './data/EntitySchema';
import {ruleSchema} from '~/rules/data/RulesApi';
import {
    adType,
    brand,
    campaign,
    category,
    industry,
    mediaType,
    mediaOwner,
    product,
    publication,
    publisher,
    region,
    entityRuleSchema
} from '~/entity-editor/data/Schemas';
import {changesetListLastUpdatedProxy} from '~/feature/ChangesetEditor/changesetListLastUpdatedSnapshot';

export default createRequestHook({
    name: 'websocket',
    schema: new ObjectSchema({
        adType,
        brand,
        campaign,
        category,
        industry,
        mediaType,
        mediaOwner,
        product,
        publication,
        publisher,
        region,
        changeset: changesetItemSchema,
        selection: changesetSelectionItem,
        selections: changesetSelectionList,
        creative: creativeItemSchema,
        rule: ruleSchema,
        entityRule: entityRuleSchema,
        release: releaseItemSchema,
        build: buildItemSchema
    }),
    request: async function* (_: void, meta) {
        for await (const message of Websocket(meta)) {
            if (typeof message !== 'string') throw new Error('Websocket message was not a string');
            const {type, data} = superjson.parse<WebsocketMessage>(message);

            switch (type) {
                case 'adTypeCreate':
                case 'adTypeDelete':
                case 'adTypeUpdate':
                    yield {adType: data};
                    break;

                case 'brandCreate':
                case 'brandDelete':
                case 'brandUpdate':
                    yield {brand: data};
                    break;

                case 'buildCreate':
                case 'buildUpdate':
                    yield {build: data};
                    break;

                case 'categoryCreate':
                case 'categoryDelete':
                case 'categoryUpdate':
                    yield {category: data};
                    break;

                case 'campaignCreate':
                case 'campaignDelete':
                case 'campaignUpdate':
                    yield {campaign: data};
                    break;

                case 'changesetCreate':
                    changesetListLastUpdatedProxy.listLastUpdated = new Date();
                    break;
                case 'changesetUpdate':
                    yield {changeset: data};
                    break;
                case 'changesetSelectionCreateMany':
                    yield {selections: data.selections, changeset: data.changeset};
                    break;
                case 'changesetSelectionUpdateMany':
                    yield {selections: data};
                    break;
                case 'changesetSelectionDelete':
                    yield {selection: data.selection, changeset: data.changeset};
                    break;
                case 'changesetSelectionDeleteMany':
                    yield {selections: data.selections, changeset: data.changeset};
                    break;

                case 'entityRuleCreate':
                    yield {entityRule: data.rule, ...data.entity};
                    break;
                case 'entityRuleUpdate':
                    yield {entityRule: data};
                    break;

                case 'industryCreate':
                case 'industryDelete':
                case 'industryUpdate':
                    yield {industry: data};
                    break;

                case 'mediaOwnerCreate':
                case 'mediaOwnerUpdate':
                    yield {mediaOwner: data};
                    break;

                case 'mediaTypeCreate':
                case 'mediaTypeDelete':
                case 'mediaTypeUpdate':
                    yield {mediaType: data};
                    break;

                case 'productUpdate':
                case 'productCreate':
                case 'productDelete':
                    yield {product: data};
                    break;

                case 'publicationCreate':
                case 'publicationDelete':
                case 'publicationUpdate':
                    yield {publication: data};
                    break;

                case 'publisherCreate':
                case 'publisherDelete':
                case 'publisherUpdate':
                    yield {publisher: data};
                    break;

                case 'regionCreate':
                case 'regionDelete':
                case 'regionUpdate':
                    yield {region: data};
                    break;

                case 'creativeUpdate':
                case 'creativeCreate':
                    yield {creative: data};
                    break;

                // deprecated rules
                case 'ruleCreate':
                case 'ruleDelete':
                case 'ruleUpdate':
                    yield {rule: data};
                    break;

                case 'jobCreate':
                case 'jobUpdate':
                    yield {job: data};
                    break;

                case 'releaseCreate':
                case 'releaseUpdate':
                    yield {release: data};
                    break;

                case 'releasePublish':
                    yield {liveRelease: data};
                    break;
            }
        }
    }
});
