import React from 'react';
import {createRoot} from 'react-dom/client';
import IndexView from '~/core/IndexView';
import './index.css';

// Shim bigints so other libraries can safely call JSON.stringify
BigInt.prototype['toJSON'] = function () {
    return this.toString();
};

createRoot(document.getElementById('root')!).render(<IndexView />);

module.hot?.accept();
