import {useEffect} from 'react';
import {Rpc} from '~/core/data/EntityApi';
import type {LinkProps} from 'react-router-dom';
import {useRoutes} from '~/core/Router';

const alertPageSize = 500;

export function useAlertData() {
    const routes = useRoutes();
    const brandAlert = Rpc.entitySearch({key: 'brandAlert'});
    const publicationAlert = Rpc.entitySearch({key: 'publicationAlert'});
    const publisherAlert = Rpc.entitySearch({key: 'publisherAlert'});

    const request = () => {
        brandAlert.request({
            type: 'brand',
            incompleteRelationships: true,
            pagination: {page: 1, count: alertPageSize}
        });
        publicationAlert.request({
            type: 'publication',
            incompleteRelationships: true,
            pagination: {page: 1, count: alertPageSize}
        });
        publisherAlert.request({
            type: 'publisher',
            incompleteRelationships: true,
            pagination: {page: 1, count: alertPageSize}
        });
    };

    useEffect(() => {
        if (brandAlert.isEmpty || publicationAlert.isEmpty || publisherAlert.isEmpty) request();
    }, []);

    const alerts: Array<{message: string; to: LinkProps<any>['to']}> = [];
    const badBrands = (brandAlert.response?.items.brand ?? []).length;
    const badPublications = (publicationAlert.response?.items.publication ?? []).length;
    const badPublishers = (publisherAlert.response?.items.publisher ?? []).length;

    const atLeast = (count: number) => (count >= alertPageSize ? `${count}+` : count);

    if (badBrands) {
        alerts.push({
            message: `There are ${atLeast(badBrands)} brands without domains`,
            to: routes.entityList.to({type: 'brand', incompleteRelationships: true})
        });
    }

    if (badPublications) {
        alerts.push({
            message: `There are ${atLeast(badPublications)} publications with missing publishers`,
            to: routes.entityList.to({type: 'publication', incompleteRelationships: true})
        });
    }

    if (badPublishers) {
        alerts.push({
            message: `There are ${atLeast(badPublishers)} publishers with missing media owners`,
            to: routes.entityList.to({type: 'publisher', incompleteRelationships: true})
        });
    }

    return {
        isFetching: brandAlert.isFetching || publicationAlert.isFetching,
        isRefetching: brandAlert.isRefetching || publicationAlert.isRefetching,
        alerts,
        request
    };
}
