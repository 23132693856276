import {useEffect} from 'react';

/** Shows a custom favicon based on the current stage (local, dev, alpha or prod).
 * How to use:
 * 1. Call this hook in the root of your app
 * 2. Create custom favicons with these names
 *      - local-favicon.ico
 *      - development-favicon.ico
 *      - staging-favicon.ico
 *      - favicon.ico
 * 3. Update webpack to copy the custom favicons
 */
function useStageFavicon() {
    useEffect(() => {
        const linkTag = document.querySelector<HTMLLinkElement>("link[rel*='icon']");
        if (!linkTag) {
            throw new Error("You don't have an icon link tag in your index.static.tsx file");
        }
        let faviconHref = '/favicon.ico';
        switch (process.env.STAGE) {
            case 'local':
            case 'development':
            case 'staging':
                faviconHref = `/${process.env.STAGE}-favicon.ico`;
                break;
            case 'production':
                faviconHref = '/favicon.ico';
                break;
        }
        linkTag.href = faviconHref;
    }, []);
}

export default typeof document !== 'undefined' ? useStageFavicon : () => {};
