import React from 'react';
import Paper from './Paper';
import Text from './Text';
import Button from './Button';
import {Box} from '../../layout';
import {Flex} from '../../layout';

type Props = {
    children: React.ReactNode;
    onRequestClose: () => void;
    title?: React.ReactNode;
    buttons?: React.ReactNode;
    maxWidth?: string;
};
export default function Modal({title, children, buttons, onRequestClose, maxWidth}: Props) {
    return (
        <Box>
            <Paper p={4} borderRadius="1rem 1rem 0 0">
                {title && (
                    <Box mb={3}>
                        <Text as="h1" textStyle="heading2">
                            {title}
                        </Text>
                    </Box>
                )}
                <Box>{children}</Box>
            </Paper>
            <Flex
                backgroundColor="background1"
                borderRadius="0 0 1rem 1rem"
                p={3}
                alignItems="center"
                justifyContent="flex-end"
            >
                {buttons || <Button onClick={onRequestClose}>Okay</Button>}
            </Flex>
        </Box>
    );
}
