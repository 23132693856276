import styled, {DefaultTheme} from 'styled-components';
import {ComponentType} from 'react';
import {color, ColorProps, layout, LayoutProps, compose} from 'styled-system';
const iconProps = compose(color, layout);

type IconProps = {
    color?: keyof DefaultTheme['colors'];
    size?: string | number;
    height?: string;
    width?: string;
    display?: string;
    opacity?: any;
    overflow?: string;
    strokeWidth?: string;
    title?: string;
} & ColorProps &
    LayoutProps;

export default function StyledIcon(
    Component: ComponentType<IconProps>,
    otherProps: Record<string, string> = {}
): ComponentType<IconProps> {
    // @ts-ignore Dont know at all what this error is. It doesn't seem to affect the usage.
    return styled(Component).withConfig({
        shouldForwardProp: (prop: string) => !['color'].includes(prop)
    })(iconProps, {display: 'block', ...otherProps});
}
