import React from 'react';
import {MappingFormProps} from '../MappingUpdateStructure';
import pick from 'unmutable/pick';
import Mapping from '../data/Mapping';
import Term from '~/affordance/Term';
import InOutForm from '~/affordance/InOutForm';
import {DropdownFilter} from 'bigdatr-style';
import {SchemaItem} from 'bigdatr-style/src/affordance/Table';

export const update = (data: Mapping) => ({
    payload: pick(['id', 'bigdatrPublicationId', 'note', 'status'])(data),
    query: `
        mutation MappingUpdate($payload: GfkStationMappingMutation!) {
            mapping {
                mappingUpdate: gfkStationMappingUpdate(mutation: $payload) {
                    id
                    type: __typename
                    createdAt
                    status
                    note
                    gfkStationName
                    bigdatrPublicationId
                    bigdatrPublicationName
                }
            }
        }
    `
});

export const choiceType = 'gfkStationChoices';
export const choiceFragment = () => `
    ... on GFKStationPublicationChoice {
        bigdatrPublicationId
        bigdatrPublicationName
    }
`;

export const queryFragment = () => `
    ... on GfkStationMapping {
        gfkStationName
        bigdatrPublicationId
        bigdatrPublicationName
    }
`;

export const tableSchema = (): Array<SchemaItem<Mapping>> => {
    return [
        {
            heading: 'GFK Station',
            key: 'gfkStationName',
            value: (mapping) => mapping.gfkStationName,
            render: (mapping) => <Term>{mapping.gfkStationName}</Term>
        },
        {
            heading: 'BigDatr Publication',
            key: 'bigdatrPublicationName',
            value: (mapping) => mapping.bigdatrPublicationName,
            render: (mapping) => <Term>{mapping.bigdatrPublicationName}</Term>
        }
    ];
};

export const Form: React.FC<MappingFormProps<Mapping>> = (props) => {
    const {mapping, onChange} = props;
    const {choices} = props;
    const id = (item: Mapping) => item.bigdatrPublicationId;
    const label = (item: Mapping) => item.bigdatrPublicationName;
    const {gfkStationName} = mapping;

    return (
        <InOutForm inTable={{'GFK Station': gfkStationName}}>
            <DropdownFilter
                selectingValueCallsOnChange
                control="Select Publication"
                successAction="Select"
                value={mapping}
                onChange={(next) => onChange({...next})}
                multi={false}
                id={id}
                label={label}
                options={choices}
            />
        </InOutForm>
    );
};
