import React from 'react';
import Numeral from 'numeral';
import styled from 'styled-components';
import {color, layout, typography, space, compose, textStyle} from 'styled-system';
import {StyledProps} from '../../layout';
import type {DefaultTheme} from 'styled-components';

import {
    formatDate,
    formatDateRange,
    formatNumber,
    formatDuration,
    parseDate,
    stringifyDate
} from '../util/textFormat';
export {formatDate, formatDateRange, formatNumber, formatDuration, parseDate, stringifyDate};

if (!Numeral.formats.rank) {
    Numeral.register('format', 'rank', {
        regexps: {
            format: /(#)/
        },
        format: (value, format, roundingFunction) => {
            // check for space before
            format = format.replace(/\s?#/, '');
            const output = Numeral._.numberToFormat(value, format, roundingFunction);

            return `#${output}`;
        }
    });
}

type Props = StyledProps & {
    //
    // General props
    children: any;
    className?: string;
    onClick?: () => void;
    /** htmlTitle */
    title?: string;
    testId?: string;

    //
    // Styling props
    textStyle?: keyof DefaultTheme['textStyles'];
    whiteSpace?: React.CSSProperties['whiteSpace'];
    wordBreak?: React.CSSProperties['wordBreak'];
    nowrap?: boolean;
    ellipsis?: boolean;
    capitalize?: boolean;
    number?: boolean;

    // Date props
    dateFormat?: string;
    dateFormatNullValue?: string;
    showFutureDates?: boolean;

    //
    // Number props
    numberFormat?: string;
    numberFormatNullValue?: string;
};

export default styled(function Text(props: Props) {
    const {
        className,
        dateFormat,
        dateFormatNullValue,
        numberFormat,
        numberFormatNullValue,
        onClick,
        showFutureDates,
        style,
        title,
        whiteSpace,
        wordBreak
    } = props;

    let formatter = (x) => x;
    if (numberFormat) formatter = formatNumber(numberFormat, numberFormatNullValue);
    if (dateFormat) formatter = formatDate(dateFormat, dateFormatNullValue, showFutureDates);

    return (
        <span
            className={className}
            style={{whiteSpace, wordBreak, ...style}}
            onClick={onClick}
            title={title}
            data-testid={props.testId}
        >
            {formatter(props.children)}
        </span>
    );
})<StyledProps>`
    ${compose(color, space, layout, typography, textStyle)}

    ${({capitalize}) => (capitalize ? 'text-transform: capitalize;' : '')}

    ${({ellipsis}) =>
        ellipsis
            ? `
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    `
            : ''}

    ${({nowrap}) => (nowrap ? `white-space: nowrap;` : '')}

    ${(_) =>
        _.number || _.numberFormat || _.numberFormatNullValue
            ? 'font-variant-numeric: tabular-nums;'
            : ''}
`;
