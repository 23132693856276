export type PublisherInput = {
    name: string;
    id: string;
};

export default class Publisher {
    private data: PublisherInput;
    type = 'publisher' as const;

    constructor(input: PublisherInput) {
        this.data = input;
    }

    get id() {
        return this.data.id;
    }

    get label() {
        return this.data.name;
    }
}
