import {AnalyticsIdentifyProps, ThirdParty} from './Analytics';

export default class Segment implements ThirdParty {
    constructor(apiKey: string) {
        if (typeof window === 'undefined') return;
        // Create a queue, but don't obliterate an existing one!
        const analytics = (window.analytics = window.analytics || []);

        // If the real analytics.js is already on the page return.
        if (analytics.initialize) return;

        // If the snippet was invoked already show an error.
        if (analytics.invoked) {
            // eslint-disable-next-line
            if (window.console && console.error) {
                // eslint-disable-next-line
                console.error('Segment snippet included twice.');
            }
            return;
        }

        // Invoked flag, to make sure the snippet
        // is never invoked twice.
        analytics.invoked = true;

        // A list of the methods in Analytics.js to stub.
        analytics.methods = [
            'trackSubmit',
            'trackClick',
            'trackLink',
            'trackForm',
            'pageview',
            'identify',
            'reset',
            'group',
            'track',
            'ready',
            'alias',
            'debug',
            'page',
            'once',
            'off',
            'on'
        ];

        // Define a factory to create stubs. These are placeholders
        // for methods in Analytics.js so that you never have to wait
        // for it to load to actually record data. The `method` is
        // stored as the first argument, so we can replay the data.
        analytics.factory = function (method) {
            return function (...args) {
                args.unshift(method);
                analytics.push(args);
                return analytics;
            };
        };

        // For each of our methods, generate a queueing stub.
        for (let i = 0; i < analytics.methods.length; i++) {
            const key = analytics.methods[i];
            analytics[key] = analytics.factory(key);
        }

        // Define a method to load Analytics.js from our CDN,
        // and that will be sure to only ever load it once.
        analytics.load = function (key, options) {
            // Create an async script element based on your key.
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';

            // Insert our script next to the first script element.
            const first = document.getElementsByTagName('script')[0];

            first.parentNode?.insertBefore(script, first);
            analytics._loadOptions = options;
        };

        // Add a version to keep track of what's in the wild.
        analytics.SNIPPET_VERSION = '4.1.0';
        window.analytics.load(apiKey);
    }

    identify(user: AnalyticsIdentifyProps) {
        const {id, teamId, teamName, ...properties} = user;
        window.analytics.identify(id, properties);
        window.analytics.group(teamId, {teamName, name: teamName});
    }

    page() {
        if (window.analytics.user) {
            const groupId = window.analytics.group().id();
            window.analytics.page({context: {groupId}});
        }
    }

    trackEvent(name: string, props: Record<string, any>, trackingOptions: Record<string, boolean>) {
        if (window.analytics.user && window.analytics.track) {
            const context = window.analytics.user().traits();
            const groupId = window.analytics.group().id();
            window.analytics.track(name, props, {...trackingOptions, ...context, groupId});
        }
    }

    chat() {}
    trackError() {}
}
