import {useCallback} from 'react';
import {OAuthPost, EagerNow} from './OAuth';
import {ApiError} from '../../error/errors';

type TokenRecord = {accessToken: string; refreshToken: string};
type Config = {
    accessToken: string | null;
    refreshToken: string | null;
    teamId: string | null;
    tokenExpiry: number | null;
    redirectUrl: string;
    onUpdateTokens: (tokens: TokenRecord) => void;
    onLogout: (redirectUrl: string) => void;
};

//
// Creates a fetch function that
// 1. adds the correct authorization headers
// 2. handles refreshing the accessToken when it is nearly expired
// 3. Parse graphql errors in >= 400 responses
// 4. logs out if the response is UNAUTHORIZED
// 5. refreshes tokens and retries once if is FORBIDDEN
export default function useAuthorizedFetch(config: Config) {
    const {teamId, tokenExpiry, redirectUrl} = config;

    let accessToken = config.accessToken;
    let refreshToken = config.refreshToken;

    async function refreshTokenRequest() {
        const next = await OAuthPost('refreshToken', refreshToken);
        accessToken = next.accessToken;
        refreshToken = next.refreshToken;
        config.onUpdateTokens({accessToken, refreshToken});
    }

    const authorizedFetch = useCallback(
        async (url, payload, method?: 'GET' | 'POST') => {
            let retryCount = 0;
            async function request() {
                try {
                    if (refreshToken && tokenExpiry && EagerNow() > tokenExpiry) {
                        await refreshTokenRequest();
                    }
                    const headers = {
                        Authorization: accessToken ? `Bearer ${accessToken}` : 'Unauthorized',
                        'Content-Type': 'application/json'
                    };
                    if (teamId) headers['BigDatr-Requester-Team'] = teamId;

                    const response = await fetch(url, {
                        method: method || 'POST',
                        headers,
                        body: JSON.stringify(payload)
                    });

                    const body = await response.json();
                    if (response.ok) return body;

                    const apiError = body.errors?.[0] || body.error;
                    if (apiError) throw new ApiError(apiError);

                    // non-graphql error ocurred
                    throw new Error(body.message ?? JSON.stringify(body));
                } catch (err) {
                    if (err.name?.toUpperCase() === 'UNAUTHORIZED') {
                        if (retryCount < 1) {
                            retryCount++;
                            await refreshTokenRequest();
                            return request();
                        } else {
                            return config.onLogout(redirectUrl);
                        }
                    }

                    if (err.name?.toUpperCase() === 'FORBIDDEN' && retryCount < 1) {
                        retryCount++;
                        await refreshTokenRequest();
                        return request();
                    }

                    if (err.message === 'Failed to fetch' && retryCount < 2) {
                        retryCount++;
                        return request();
                    }

                    return Promise.reject(err);
                }
            }
            return request();
        },
        [teamId, accessToken, refreshToken, tokenExpiry]
    );

    return authorizedFetch;
}
