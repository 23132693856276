import React from 'react';
import DeadEnd from '../affordance/DeadEnd';
import Stampy from '../scenes/stampy_in_box';

export default function DeadEndCampaignNotFound() {
    return (
        <DeadEnd title="Sorry, stampy couldn't find this campaign" stampy={Stampy}>
            {'Please try searching again'}
        </DeadEnd>
    );
}
