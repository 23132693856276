import {EntitySchema, ArraySchema, ObjectSchema} from 'react-enty';

//
// rule
export const entityRuleSchema = new EntitySchema('EntityRule', {id: (e) => e.id});

// single
export const adType = new EntitySchema('AdType', {id: (e) => e.id});
export const brand = new EntitySchema('Brand', {id: (e) => e.id});
export const campaign = new EntitySchema('Campaign', {id: (e) => String(e.id)});
export const category = new EntitySchema('Category', {id: (e) => e.id});
export const industry = new EntitySchema('Industry', {id: (e) => e.id});
export const mediaType = new EntitySchema('MediaType', {id: (e) => e.id});
export const mediaOwner = new EntitySchema('MediaOwner', {id: (e) => e.id});
export const product = new EntitySchema('Product', {id: (e) => e.id});
export const publication = new EntitySchema('Publication', {id: (e) => String(e.id)});
export const publisher = new EntitySchema('Publisher', {id: (e) => e.id});
export const region = new EntitySchema('Region', {id: (e) => e.id});

//
// list
const adTypeList = new ArraySchema(adType);
const brandList = new ArraySchema(brand);
const campaignList = new ArraySchema(campaign);
const categoryList = new ArraySchema(category);
const industryList = new ArraySchema(industry);
const mediaTypeList = new ArraySchema(mediaType);
const mediaOwnerList = new ArraySchema(mediaOwner);
const productList = new ArraySchema(product);
const publicationList = new ArraySchema(publication);
const publisherList = new ArraySchema(publisher);
const regionList = new ArraySchema(region);

//
// relationships
adType.shape = new ObjectSchema({
    rule: entityRuleSchema
});

industry.shape = new ObjectSchema({
    rule: entityRuleSchema
});

brand.shape = new ObjectSchema({
    industries: industryList,
    rule: entityRuleSchema
});

category.shape = new ObjectSchema({
    industry,
    rule: entityRuleSchema
});

product.shape = new ObjectSchema({
    brand,
    categories: categoryList,
    rule: entityRuleSchema
});

publication.shape = new ObjectSchema({
    mediaTypes: mediaTypeList,
    region,
    publisher
});

publisher.shape = new ObjectSchema({
    mediaOwner
});

export function getEntityItemSchema() {
    return new ObjectSchema({
        adType,
        brand,
        campaign,
        category,
        industry,
        mediaType,
        mediaOwner,
        product,
        publication,
        publisher,
        region
    });
}

export function getEntityListSchema() {
    return new ObjectSchema({
        items: new ObjectSchema({
            adType: adTypeList,
            brand: brandList,
            campaign: campaignList,
            category: categoryList,
            industry: industryList,
            mediaType: mediaTypeList,
            mediaOwner: mediaOwnerList,
            product: productList,
            publication: publicationList,
            publisher: publisherList,
            region: regionList
        })
    });
}

export function getPublisherAndMediaOwnerSchema() {
    return new ObjectSchema({
        mediaOwner,
        publisher
    });
}

// keep this file in the same place as the referenced schemas below.
// otherwise you will create a circular dependency and go nuts
export const entityRuleCreateSchema = new ObjectSchema({
    rule: entityRuleSchema,
    entity: new ObjectSchema({
        adType,
        brand,
        category,
        industry,
        product
    })
});
