import React from 'react';
import DeadEnd from '../affordance/DeadEnd';
import Stampy from '../scenes/stampy_in_box';

export default function DeadEndResponseBodyTooLarge() {
    return (
        <DeadEnd
            title="Your request was too large to process."
            stampy={Stampy}
            children={'Try selecting less columns, or choose a smaller date range.'}
        />
    );
}
