import {SupportedCountry} from 'bigdatr-style/src/team/Team';
import {DataEntryRpc} from '~/core/data/Endpoints';
import {isAllowedSelectionCriteriaKey} from '../filterKeys';
import {validateOpenSearchQueryString} from '../util/util';

/** Converts the API `Query` type into a structure with which we can eeasily work with on the client */
export function parseQuery(
    filter: Query | undefined,
    options?: {nestLevel?: number; addDefaultFiltersOnEmpty?: boolean}
): Expression {
    const {nestLevel = 0, addDefaultFiltersOnEmpty = true} = options || {};
    if (!filter) {
        return rootGroups(
            addDefaultFiltersOnEmpty
                ? [
                      {type: 'binary', key: 'status', operator: '=', value: 'ACTIVE', id: id()},
                      {
                          type: 'binary',
                          key: 'country',
                          operator: '=',
                          value: SupportedCountry.AU,
                          id: id()
                      }
                  ]
                : []
        );
    }

    switch (filter[0]) {
        case 'exists':
        case 'notExists': {
            const [operator, key] = filter;
            const unaryExpression = {type: 'unary', operator, key, id: id()} as const;

            if (nestLevel === 0) return rootGroups([unaryExpression]);
            return unaryExpression;
        }

        case '=':
        case '!=':
        case 'contains':
        case 'containsExact':
        case 'containsAll':
        case 'containsAny':
        case 'query':
        case 'simple_query':
        case '<':
        case '<=':
        case '>':
        case '>=': {
            const [operator, key, value] = filter;
            const binaryExpression = {type: 'binary', operator, key, value, id: id()} as const;

            if (nestLevel === 0) return rootGroups([binaryExpression]);
            return binaryExpression;
        }

        case 'range': {
            const [operator, key, value] = filter;
            const ternaryExpression = {type: 'ternary', operator, key, value, id: id()} as const;

            if (nestLevel === 0) return rootGroups([ternaryExpression]);
            return ternaryExpression;
        }

        case 'AND':
        case 'OR':
        case 'NOT': {
            const [operator, ...restOfQuery] = filter;

            // convert a query of 1 level deep to be 2 levels deeps, cause it looks weird otherwise
            if (nestLevel === 0 && restOfQuery.every((q) => isEqualityOperator(q[0]))) {
                return {
                    type: 'group',
                    operator: 'AND',
                    items: [
                        {
                            operator,
                            type: 'group',
                            nestLevel: 1,
                            id: id(),
                            items: restOfQuery.map((ii) =>
                                parseQuery(ii, {nestLevel: nestLevel + 1})
                            )
                        }
                    ],
                    nestLevel,
                    id: id()
                };
            }

            return {
                type: 'group',
                operator,
                items: restOfQuery.map((ii) => parseQuery(ii, {nestLevel: nestLevel + 1})),
                nestLevel,
                id: id()
            };
        }
        default:
            throw new Error('Unknown error parsing filters');
    }
}

export function parseOperator(operator: Operator) {
    switch (operator) {
        case 'exists':
        case 'notExists': {
            return {type: 'unary', operator} as const;
        }

        case '=':
        case '!=':
        case 'contains':
        case 'containsExact':
        case 'containsAll':
        case 'containsAny':
        case 'query':
        case 'simple_query':
        case '<':
        case '<=':
        case '>':
        case '>=': {
            return {type: 'binary', operator} as const;
        }

        case 'range': {
            return {type: 'ternary', operator} as const;
        }

        case 'AND':
        case 'OR':
        case 'NOT': {
            return {type: 'ternary', operator} as const;
        }

        default:
            throw new Error('Unknown error parsing operator');
    }
}

/** @intent Evaluates the whole `Query` if it has a valid definition for rule selection criteria
 *
 * we need to be stricter in what we consider a valid filter is for a rule. For example,
 * having just a status = active filter is not good enough.
 * We consider a filter is good enough for a rule if it has creative attribute filters
 */
export function isValidRuleQuery(input?: Query) {
    if (!input) return false;
    return !!countValidExpressions({expression: parseQuery(input), mode: 'rule'});
}

/** Evaluates a single expression, and returns a boolean if the expression belongs in rule selection
 * criteria */
export function isValidRuleExpression(key: string, type: Expression['type']) {
    // we don't consider unary operator filters to be good enough for a rule filter.
    // you need to add more filters, otherwise the rule filter is way to broad
    if (type === 'unary') return false;
    return isAllowedSelectionCriteriaKey(key);
}

export function countValidExpressions(input: {
    expression: Expression;
    mode: 'rule' | 'general';
}): number {
    let count = 0;

    function validator(key: string, type: Expression['type']) {
        if (input.mode === 'rule') return isValidRuleExpression(key, type);
        return true;
    }

    function checkExpression(e: Expression) {
        switch (e.type) {
            case 'unary': {
                if (validator(e.key, e.type)) count++;
                break;
            }
            case 'binary': {
                if (validator(e.key, e.type) && e.value.trim().length > 0) count++;
                break;
            }
            case 'ternary': {
                if (
                    validator(e.key, e.type) &&
                    // both values have some values
                    e.value[0].trim().length > 0 &&
                    e.value[1].trim().length > 0
                ) {
                    count++;
                }
                break;
            }
            case 'group': {
                e.items.forEach((group) => checkExpression(group));
                break;
            }
            default: {
                throw new Error('Unkown error parsing filters');
            }
        }
    }

    checkExpression(input.expression);

    return count;
}

/** Converts the client only version of filters into the API shape `Query` */
export function toQuery(input?: Expression, addDefaultFiltersOnEmpty = true): Query | undefined {
    if (!input) {
        if (addDefaultFiltersOnEmpty)
            return ['AND', ['=', 'status', 'ACTIVE'], ['=', 'country', SupportedCountry.AU]];
        return undefined;
    }

    switch (input.type) {
        case 'unary': {
            return [input.operator, input.key] as Query;
        }
        case 'binary': {
            return [input.operator, input.key, input.value] as Query;
        }
        case 'ternary': {
            return [input.operator, input.key, input.value] as Query;
        }
        case 'group': {
            return [
                input.operator,
                ...input.items.map((ii) => toQuery(ii)).filter((i): i is Query => !!i)
            ];
        }
        default: {
            throw new Error('Unknown error parsing filters');
        }
    }
}

/** Used to determine wether we need to refresh creative lists as filters are added.
 * It mostly exists for a nicer UX.
 *
 * Without it, the creative list would refresh at moments like these:
 * 1. Add a Brand filter without selecting a brand
 * 2. Add a date filter without selecting the dates
 */
export function cleanEmptyQueriesAndValidate(input?: Query) {
    const asExpresion = parseQuery(input, {addDefaultFiltersOnEmpty: false});
    const cleaned = removeEmptyExpressions(asExpresion);
    const hasValidationError = validateExpression(asExpresion);
    return {
        filters: toQuery(cleaned ?? undefined, false),
        hasValidationError
    };
}

function removeEmptyExpressions(exp: Expression): Expression | null {
    switch (exp.type) {
        case 'unary': {
            return exp;
        }
        case 'binary': {
            // return null to indicate that this object should be removed
            if (exp.value.trim().length === 0) return null;
            return exp;
        }
        case 'ternary': {
            if (exp.value[0].trim().length === 0 || exp.value[1].trim().length === 0) return null;
            return exp;
        }
        case 'group': {
            const items = exp.items
                .map((item) => removeEmptyExpressions(item))
                .filter((item): item is Expression => item !== null);

            if (items.length === 0) return null;
            return {...exp, items};
        }
        default: {
            throw new Error('Unkown error parsing filters');
        }
    }
}

/** We need a unique ID across rerenders for filter expressions.
 * Previously we tried using array indexes, but that created several rendering issues, since filter
 * expressions can change position (due to removing a filter).
 * We also tried to key it based of the attribute, but thats not good because you can have
 * several filters with the same attribute.
 */
export function id() {
    return Math.random();
}

export function group(
    nestLevel: number,
    items: Expression[],
    operator: BooleanOperator = 'AND'
): Group {
    return {
        type: 'group',
        operator,
        nestLevel,
        items,
        id: id()
    };
}

/** Make filters 2 levels deep, mostly because the UI as we have it looks weird otherwise */
function rootGroups(items: Expression[]) {
    return group(0, [group(1, items)]);
}

function isEqualityOperator(input: string) {
    return ['=', '!=', 'contains', '<', '<=', '>', '>=', 'range'].includes(input);
}

/** At the moment this just checks if a `query` filter would cause a 404 for opensearch */
export function validateExpression(input: Expression) {
    const invalidItems: Expression[] = [];
    function validate(e: Expression) {
        switch (e.type) {
            case 'binary':
                if (e.operator === 'query') {
                    const isValid = validateOpenSearchQueryString(e.value ?? '');
                    if (!isValid) invalidItems.push(e);
                }
                break;
            case 'group':
                e.items.forEach((item) => validate(item));
                break;
            case 'unary':
            case 'ternary':
            default:
                break;
        }
    }

    validate(input);

    return invalidItems.length > 0;
}

/** When pasting filters, we need to give them a new & unique ID. Otherwise react will get confused
 * because 2 items in a list will have the same key. Optionally increase the group nestLevel too */
export function mapNewIdsToExpressions<T extends Expression>(
    expressions: T[],
    increaseNestLevel = false
) {
    // doing this so all the object references go away and its safe to mutate the id directly
    const copy: T[] = JSON.parse(JSON.stringify(expressions));

    function setNewId(input: Expression) {
        switch (input.type) {
            case 'unary':
            case 'binary':
            case 'ternary':
                input.id = id();
                break;
            case 'group':
                input.id = id();
                if (increaseNestLevel) input.nestLevel += 1;
                input.items.forEach((expression) => setNewId(expression));
                break;
            default:
                throw new Error('Unknown filter type');
        }
    }

    copy.forEach((expression) => setNewId(expression));

    return copy;
}

export function fetchGroupFilterItems(
    input:
        | {mode: 'getAllChildren'; data: Expression}
        | {
              mode: 'getByCustomMatcher';
              data: Expression;
              matcher: (e: OperatorExpression) => boolean;
          }
) {
    const matchedExpressions: Expression[] = [];

    function find(e: Expression) {
        switch (e.type) {
            case 'unary':
            case 'binary':
            case 'ternary':
                if (input.mode === 'getAllChildren') matchedExpressions.push(e);
                else if (input.matcher(e)) matchedExpressions.push(e);
                break;
            case 'group':
                e.items.forEach((item) => find(item));
                break;
            default:
                throw new Error('Unknown filter type');
        }
    }
    find(input.data);

    return matchedExpressions;
}

/**
 * Used for figuring out if two different expressions are essentially the same.
 *
 * We can't use expression.id, because those are different for every expression.
 */
function compositeId(expression: UnaryExpression | BinaryExpression | TernaryExpression) {
    return `${expression.type}-${expression.operator}-${expression.key}-${expression.value
        ?.toString()
        .trim()}`;
}

export function findDuplicates(group: Group) {
    const uniqueElementsCompositeIds = new Set<string>();
    const duplicateElementFilterIds = new Set<number>();

    for (let i = 0; i < group.items.length; i++) {
        const filter = group.items[i];
        switch (filter.type) {
            // ignore groups so we dont check recursively. dont think its hard to implement this,
            // but feels like a rare usecase and maybe not worth the time for now
            case 'group': {
                continue;
            }

            default: {
                const compositeid = compositeId(filter);
                if (uniqueElementsCompositeIds.has(compositeid))
                    duplicateElementFilterIds.add(filter.id);
                else uniqueElementsCompositeIds.add(compositeid);
            }
        }
    }

    return duplicateElementFilterIds;
}

export type Expression = UnaryExpression | BinaryExpression | TernaryExpression | Group;
export type OperatorExpression = UnaryExpression | BinaryExpression | TernaryExpression;

export type Query = NonNullable<DataEntryRpc['creativeList']['payload']['query']>;
export type Presets = NonNullable<DataEntryRpc['creativeList']['payload']['presets']>;
export type OrderBy = NonNullable<DataEntryRpc['creativeList']['payload']['orderBy']>;

export type BooleanOperator = 'OR' | 'AND' | 'NOT';
export type BooleanExpression = [BooleanOperator, ...Query[]];

export function isBooleanExpression(input: Query): input is BooleanExpression {
    return ['AND', 'OR', 'NOT'].includes(input[0]);
}

export type Group = {
    type: 'group';
    operator: BooleanOperator;
    items: Array<ReturnType<typeof parseQuery>>;
    nestLevel: number;
    id: number;
};

export type UnaryOperator = 'exists' | 'notExists';
export type UnaryExpression = {
    type: 'unary';
    operator: UnaryOperator;
    value?: undefined; // This is added so you can at least destructure value before the discriminating
    key: string;
    id: number;
};

export type BinaryOperator =
    | '='
    | '!='
    | 'contains'
    | 'containsAny'
    | 'containsExact'
    | 'containsAll'
    | 'query'
    | 'simple_query'
    | '<'
    | '<='
    | '>'
    | '>=';
export type BinaryExpression = {
    type: 'binary';
    operator: BinaryOperator;
    key: string;
    value: string;
    id: number;
};

export type TernaryOperator = 'range';
export type TernaryExpression = {
    type: 'ternary';
    operator: TernaryOperator;
    key: string;
    value: [string, string];
    id: number;
};

export type EqualityOperator = BinaryOperator | TernaryOperator;
export type Operator = UnaryOperator | BinaryOperator | TernaryOperator | Group;
