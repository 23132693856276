import {ThemeType} from '../Theme';
type ThemeProps = {theme: ThemeType};

const makeGetter = <T extends keyof ThemeType>(type: T) => {
    return (key: keyof ThemeType[T]) => (props) => props.theme[type][key];
};

export const color = makeGetter('colors');
export const border = makeGetter('borders');
export const breakpoint = makeGetter('breakpoints');
export const zindex = makeGetter('zIndices');
export function bool<Props = {}>(key: string) {
    return (fn: (props: Props & ThemeProps) => string) => (props: Props & ThemeProps) =>
        props[key] ? fn(props) : '';
}
