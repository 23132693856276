import {GraphDimension, GraphNodeType, GraphMetric} from '../ChartDefinitions';
import {formatDate} from '../../Text';
import {formatNumber} from '../../Text';
import {isSame} from 'stringdate';
import {SupportedCountry} from '../../../team/Team';

// @intent
// Given a graph definition return the corresponding
// property required to render a chart.
// e.g. 'industry_name' => 'Industry'
// e.g. 'spot_spend:1000' => '$1,000'

export const UNATTRIBUTABLE_LABEL = 'Various';

const labelObject = {
    adType: 'Ad Type',
    advertiserDomain: 'Advertiser Domain',
    advertiserName: 'Advertiser Name',
    brand: 'Brand',
    brand_name: 'Brand',
    campaign: 'Campaign',
    campaignId: 'Campaign',
    creativeTheme: 'Campaign',
    creative_theme: 'Campaign',
    category: 'Category',
    category_name: 'Category',
    creative_adType: 'Ad Type',
    creative_mediaType: 'Media Type',
    creative_region: 'Region',
    industry: 'Industry',
    industry_name: 'Industry',
    location: 'Location',
    mediaType: 'Media Type',
    mediaOwner: 'Media Owner',
    placementType: 'Out of Home Placement',
    product: 'Product',
    product_name: 'Product',
    publication: 'Publication',
    publicationId: 'Publication',
    publisher: 'Publisher',
    region: 'Region',
    release: 'Release',
    spot_month: 'Month',
    spot_spend: 'Media Value',
    status: 'Status',
    universe: 'Universe',
    segment: 'Segment'
};
export function columnLabel(name: string, country = SupportedCountry.AU): string {
    const label = labelObject[name] || name;
    if (country === SupportedCountry.AU) return label;
    // for non-au markets, we query publications, but label it as publisher in the ui. this is done
    // because the manual overhead of creating publishers for each market is not scallable
    if (name === 'publication') return labelObject.publisher;
    return label;
}

export const format = ({
    type,
    cutoff,
    extent
}: {
    type: GraphDimension | GraphMetric | GraphNodeType | string | null;
    cutoff?: string;
    extent?: string;
}) => {
    switch (type) {
        case 'value':
            return formatNumber('$0,0', '$0');

        case 'date':
            return (date: string) => {
                const toString = formatDate("MMM ''yy");
                const forecastedText = `${toString(date)} (Prelim)`;

                if (
                    extent &&
                    isSame('month', date)(extent) &&
                    isSame('month', date)(new Date().toISOString())
                ) {
                    return `1-${formatDate('d')(new Date())} ${forecastedText}`;
                }
                if (new Date(date) >= new Date(cutoff ?? new Date())) return forecastedText;
                return formatDate(`MMM ''yy`)(date);
            };

        default:
            return (x: any) => x;
    }
};

export type Entityish = {
    readonly color?: string;
    readonly label: string;
    readonly name?: string;
    readonly sequence?: number;
    readonly to?: string;
};
