import React from 'react';
import {BoringRoute} from 'bigdatr-style';
import {FlexColumn} from 'bigdatr-style/layout';
import {Text, Button} from 'bigdatr-style';
import {useRoutes} from '~/core/Router';

export default BoringRoute({
    path: '/done',
    component: CreativeDone
});

function CreativeDone() {
    const {creativeTagger} = useRoutes();
    return (
        <FlexColumn p={5} gap={3} alignItems="center">
            <img
                style={{
                    objectFit: 'cover',
                    overflow: 'hidden',
                    borderRadius: '100rem',
                    aspectRatio: '1',
                    width: '30vw'
                }}
                src={`//media.bigdatr.com/static/app/dataentry-done-gifs/${
                    Math.floor(Math.random() * 10) + 1
                }.gif`}
            />
            <FlexColumn alignItems="center">
                <Text as="h1" textStyle="heading1">
                    Great Job!
                </Text>
                <Text>You&apos;ve finished tagging these creatives.</Text>
            </FlexColumn>
            <Button to={creativeTagger.to({})}>Back home</Button>
        </FlexColumn>
    );
}
